.site-map-menus {
  @include make-row();

  .site-map-box {
    @include make-xs-column(12);
    @include make-md-column(6);

    &.site-map-box-menu-menu-footer-menu {
      float: right;
    }

    h2 {
      font-size: $font-size-base * 1.7;
      margin-bottom: 20px;
    }

    .content {
      .site-map-menu {
        &, ul {
          padding-left: 1.5em !important;
          list-style: none;
        }

        li {
          border-left: 1px dotted transparentize($text-color, 0.7);
          padding-left: 2em;
          position: relative;

          &:before {
            background-color: #FFF;
            border-bottom: 1px dotted transparentize($text-color, 0.7);
            content: '';
            display: block;
            height: 1em;
            left: 0;
            position: absolute;
            top: -0.1em;
            width: 1.4em;
          }

          &:last-child {
            border-left: 0;

            &:before {
              border-left: 1px dotted transparentize($text-color, 0.7);
            }
          }
        }

        & > li {
          &:first-child {
            &:before {
              border-left: 1px dotted transparentize($text-color, 0.7);
              height: 1.5em;
              left: -1px;
              top: -0.6em;
            }
          }
        }

        ul {
          margin-left: 1.5em;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}