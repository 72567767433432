/**
 * BLOCKS - Alerts.
 */
.alerts-front-wrapper {
  background: $alert-warning-bg;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  margin: 3em 0 0 0;
  padding: 0 15px;

  @media #{$tablet} {
    margin: 3em (-$container-padding-sm) 0;
  }
  @media #{$normal} {
    margin: 3em (-$container-padding-lg) 0;
  }

  h3 {
    svg {
      color: $alert-warning-text;
    }
  }

  .slick-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;

    li {
      display: inline-block;
      line-height: 0;
      margin: 0 2px;

      button {
        background-color: transparentize(#fff, 0.5);
        border: 1px solid lighten($gray-base, 70%);
        border-radius: 50%;
        color: transparent;
        font-size: 0;
        height: 9px;
        line-height: 0;
        margin: 0;
        padding: 0;
        transition: all .2s;
        width: 9px;
      }

      &:hover,
      &:focus,
      &.slick-active {
        button {
          background-color: lighten($gray-base, 70%);
        }
      }
    }
  }

  .alert-line-concerned {
    font-size: 0.8em;
  }

  .alert-icon-wrapper {
    svg {
      margin-top: 30px;
      width: auto;
      height: auto;
      color: #b81414;
      opacity: 0.35;
    }
  }

}
