/**
 * BLOCKS - Footer.
 */
.footer {
  align-items: center;
  display: flex;

  @media all and (min-width: $screen-sm) {
    height: 84px;
  }

  img {
    height: 28px;

    @media all and (min-width: $screen-sm) {
      height: 36px;
    }
  }

  p {
    line-height: 1.2;
    margin: 2px 0 0;
  }

  .row {
    position: relative;

    > div {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: left;

      @media all and (min-width: $screen-sm) {
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        text-align: center;
      }

      @media #{$normal} {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  #block-menu-menu-footer-menu {
    h2 {
      display: none;
    }

    .menu {
      line-height: 1.2;
      list-style: none;
      margin-top: 5px;
      padding: 0;
      text-align: left;

      @media all and (min-width: $screen-sm) {
        margin: 0;
        text-align: center;
      }

      .leaf {
        @media all and (min-width: $screen-sm) {
          display: inline-block;
        }

        a {
          color: #fff;
          display: inline-block;
          padding: 7px 0;

          @media all and (min-width: $screen-sm) {
            padding: 0;
          }

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        & + .leaf {
          @media all and (min-width: $screen-sm) {
            margin-left: -4px;
          }

          &:before {
            @media all and (min-width: $screen-sm) {
              border-left: 1px solid #fff;
              content: '';
              display: inline-block;
              height: 1em;
              margin: 0 7px 0 10px;
              vertical-align: top;
              width: 0;
            }
          }
        }
      }
    }
  }
}

#block-w-tpgal-common-w-tpgal-common-contact-form {
  @include make-md-column(6);

  form {
    .form-item:not(.form-type-checkbox) {
      .control-label {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        width: 1px;
      }
    }

    .form-control {
      color: #fff;

      &::placeholder {
        color: #fff;
      }
    }

    .legal-link {
      a {
        border-bottom: 2px solid transparentize(#fff, 0.7);
        color: #fff;

        &:hover,
        &:focus {
          border-bottom-color: $brand-primary;
          color: $brand-primary;
        }
      }
    }

    .form-item-legal-checkbox {
      & > label {
        & > .text {
          &:before {
            border: 1px solid transparentize(#fff, 0.7);
          }
        }

        input {
          &:focus {
            & ~ .text {
              &:before {
                border-color: $brand-primary;
              }
            }
          }

          &:checked {
            & ~ .text {
              &:before {
                background-color: $brand-primary;
                border-color: $brand-primary;
              }
            }
          }
        }

        &:hover,
        &:focus {
          & > .text {
            &:before {
              background-color: transparentize(#fff, 0.7);
            }
          }
        }
      }

      .form-required {
        color: #fff;
      }
    }
  }
}

#block-w-tpgal-common-w-tpgal-common-contact {
  @include make-md-column(5);
  @include make-md-column-offset(1);
  @include make-lg-column(4);
  @include make-lg-column-offset(2);

  .phone,
  .email {
    font-size: .9em;
    line-height: 1.7;

    a {
      color: #FFF;
    }

    b, strong {
      font-size: 1.1em;
    }
  }

  .social_networks {
    margin-top: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;

        & + li {
          margin-left: 5px;
        }

        a {
          align-items: center;
          border: 1px solid;
          border-radius: 50%;
          box-sizing: content-box;
          color: #fff;
          display: flex;
          height: $social-btn-size-lg;
          justify-content: center;
          text-align: center;
          transition: all .2s;
          width: $social-btn-size-lg;

          span {
            display: inline-block;
            height: $social-btn-size-lg;
            overflow: hidden;
            vertical-align: middle;
            width: 0;
          }

          i, .svg-inline--fa {
            font-size: $social-btn-size-lg / 2;
            vertical-align: middle;
          }

          &:hover {
            color: $brand-primary;
          }

          @media all and (max-width: $screen-sm) {
            width: $social-btn-size-sm;
            height: $social-btn-size-sm;

            span {
              height: $social-btn-size-sm;
            }

            i, .svg-inline--fa {
              font-size: $social-btn-size-sm / 2;
            }
          }
        }
      }
    }
  }

  .app-icons {
    margin-top: 2.5em;

    .android-ios-icons {
      margin-top: 6px;

      div {
        img {
          height: 40px;
        }

        &:last-child {
          img {
            margin-top: 6px;
            @media (min-width: $screen-md-min) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: $screen-sm) {
    margin-top: 50px;
  }
}

.pre-footer {
  .logo-feder {
    margin-top: 2em;
    width: 200px;

    @media #{$tablet} {
      float: right;
      margin-top: -50px;
    }
  }
}

.page-contact {
  .pre-footer {
    padding: 1em 0;

    .logo-feder {
      margin-top: 0;
    }
  }
}
