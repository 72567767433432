form {
  .form-type-textfield {
    margin-bottom: 1em;
  }

  .form-item-tripdate {
    .date-padding {
      @include make-row();

      .form-item {
        @include make-sm-column(6);

        @media all and (min-width: $screen-sm) {
          &.form-item-tripdate-time {
            padding-left: 0;
          }
        }
      }
    }
  }

  .form-item-realtime {
    font-size: .9em;
    @include checkbox;
  }

  .required-txt {
    color: $gray;
    font-size: .85em;
    margin-top: 10px;
  }

  .form-actions {
    clear: none;

    .btn {
      margin-bottom: 5px;
      margin-left: 15px;

      &.btn-primary {
        float: right;
      }

      @media all and (max-width: $screen-xs) {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}
