/**
 * SECTION - Booking.
 */

/**
  BREADCRUMB.
 */
.booking-breadcrumb-wrapper {
  /* Width 100% */
  left: 50%;
  margin-bottom: 3em;
  margin-left: -50vw;
  position: relative;
  width: 100vw;

  &:before {
    background: #000;
    content: ' ';
    height: 1px;
    position: absolute;
    top: 100%;
    width: 100%;
    @media #{$tablet} {
      top: 50%;
    }
  }

  .booking-breadcrumb {
    display: flex;
    justify-content: space-between;
    position: relative;
    text-align: center;

    .step {
      a {
        color: $text-color;
      }
      position: relative;
      text-transform: uppercase;

      .text {
        display: none;
        @media #{$tablet} {
          display: block;
        }
      }

      .text, .icon {
        height: 52px;
      }
      .icon {
        margin-bottom: 1em;
        @media #{$tablet} {
          margin-bottom: 2em;
        }
      }

      &:before {
        background: #000;
        border-radius: 10px;
        content: ' ';
        height: 10px;
        left: 50%;
        margin-left: -5px;
        margin-top: -5px;
        position: absolute;
        top: 100%;
        width: 10px;
        @media #{$tablet} {
          top: 50%;
        }
      }

      &.selected {
        .icon {
          color: $brand-primary;
        }
        .text {
          font-size: 1.2em;
          font-weight: 700;
        }
        &:before {
          background-color: $brand-primary;
          height: 18px;
          margin-left: -9px;
          margin-top: -9px;
          width: 18px;
        }
      }
    }
  }
}
/**
  EXPEDITION INFO.
 */
.booking-expedition-info {
  h2 {
    margin: 0;
  }
  p {
    margin-bottom: 15px;
  }

  .date {
    margin-top: 0;
  }

  .booking-tip-regular {
    svg {
      vertical-align: -0.2em;
    }
    span {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .booking-expedition-data {
    display: inline-block;
  }

  .booking-busstop-price {
    text-align: left !important;
    display: inline-block;
    margin-right: 0 !important;
    @media #{$normal} {
      float: right;
    }

    &:after {
      display: none;
    }

    .discounts {
      border-top: 1px dashed #aaa;
      margin-top: 10px;
    }
  }

  .booking-arrow-transfer {
    text-align: center;
    > * {
      font-size: 5em;
      color: #ccc;
    }
  }

  .booking-busstops-wrapper {
    @media #{$normal} {
      display: flex;
      justify-content: space-between;
    }
    .busstop-group {
      flex: 0 40%;
      &.busstop-group-clock {
        flex: 0 20%;
      }

      .booking-busstops-regular {
        border-bottom: 6px solid #2a2a2a;
      }
      .booking-busstops-demand {
        border-bottom: 6px solid $brand-primary;
      }
      .booking-busstops-clock {
        height: 20%;
        border-bottom: 6px dotted #2a2a2a;
        display: block !important;
        text-align: center;
        svg {
          margin-top: 15px;
          font-size: 3.5em;
        }
        p {
          font-size: 1.8em;
          font-weight: 700;
        }
        @media #{$normal} {
          margin-right: 1.5em;
          margin-left: 1.5em;
        }
      }
      .booking-group {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 1em;

        @media #{$normal} {
          flex-direction: row;
        }
      }
    }
  }
  .booking-busstop {
    flex: 0 50%;
    align-content: center;
    background: $gray-lighter;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2.5em;
    padding: 1em;
    position: relative;
    text-align: center;

    @media #{$normal} {
      flex-grow: 1;
      margin-bottom: 1em;
      margin-right: 5em;
      vertical-align: top;
    }

    &:after {
      border-color: $brand-primary;
      border-style: solid;
      border-width: 0 3px 3px 0;
      bottom: -25px;
      content: ' ';
      height: 30px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      transform: rotate(45deg);
      width: 30px;

      @media #{$normal} {
        margin-top: -15px;
        left: unset;
        right: -3em;
        top: 50%;
        transform: rotate(-45deg);
      }
    }

    &:last-child {
      margin-bottom: 1em;
      margin-right: 0;

      &:after {
        display: none;
      }
    }
    h3, p {
      font-weight: 400;
      margin: 0;
    }
    .time {
      font-size: 2.5em;
      line-height: 1.4em;
    }
  }
}
.booking-separator {
  /* Width 100% */
  left: 50%;
  margin-bottom: 2em;
  margin-left: -50vw;
  margin-top: 2em;
  position: relative;
  width: 100vw;

  &:before {
    border-top: 1px dashed $gray-light;
    content: ' ';
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}
.qr-booking-cancelled {
  @media (max-width: $screen-md-min) {
    font-size: 1.6em;
    transform-origin: unset;
    transform: unset;
  }
  @media (max-width: $screen-lg-min) {
    font-size: 1.6em;
  }
  border-radius: 0.3em;
  color: #ffacac;
  font-size: 2em;
  transform: rotate(45deg);
  display: inline-block;
  font-weight: bold;
  transform-origin: bottom left;
  border: 1px solid;
  padding: 0 0.5em;
  text-transform: uppercase;
}
.booking-ticket-assoc {
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: -3em;
  margin-left: -15px;
  color: $brand-primary;
}
.booking-assoc-separator {
  margin: 2em -15px 2em -15px;
  position: relative;

  &:before {
    border-top: 1px dashed $gray-light;
    content: ' ';
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
}
.search-return-block {
  padding-top: 1em;
  padding-bottom: 1em;
}
.fa-exclamation-triangle {
  color: #dd932e;
  margin-right: 0.50em;
}
.new-booking-alert-info {
  margin-bottom: 50px;
  .bstop-message-transfer {
    font-weight: bold;
    font-style: italic;
  }
}
/**
  Fieldset.
 */
.booking-fieldset-wrapper {
  margin-bottom: 2em;

  fieldset {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 0;

    legend.panel-heading {
      background: none;
      border: 0;
      font-size: 1em;
      margin-bottom: 1em;
      padding: 0;

      .panel-title {
        font-size: 1.5em;
        line-height: 1.3em;
        margin: 0;
        text-align: left;

        &:before {
          display: none;
        }
      }
    }

    .panel-body {
      padding: 0;

      .form-item {
        margin-bottom: 1em;
      }
    }
  }
}

.confirmation-actions {
  padding-top: 20px;
}

.page-booking-schedule,
.page-booking-new {
  .form-actions {
    .btn-primary {
      margin-bottom: 1em;
      @media #{$tablet} {
        float: right;
        margin-left: 1em;
      }
    }
    & > * {
      margin-bottom: 1em;
      @media (max-width: $screen-md-min) {
        display: inline-block;
        width: 100%;
      }
    }
  }

  .alert-warning-list {
    display: inline-block;
    vertical-align: top;
    list-style-type: square;
  }
  .message-inner-separator {
    clear: both;
    margin-top: 4px;
    margin-bottom: 10px;
    height: 1px;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }
}
/*
  Search booking.
*/
.page-booking-search,
.page-booking-remember {
  h1 {
    margin-bottom: 1em;
  }
  .form-actions {
    .btn {
      width: 100%;
      margin-left: 0 !important;
      @media #{$tablet} {
        display: inline-block;
        width: auto;
        margin-left: 15px !important;
      }
    }
  }
}
/*
  Schedule booking.
 */
.modal-content,
.page-booking-schedule {

  .panel-body {
    padding: 0;

    .form-radios {
      padding-top: 0.7em;
      margin-left: 1em;
    }
    .form-type-checkbox {
      width: 150px;
      display: inline-table;
    }

    .container-inline-date {
      clear: none;
      * {
        float: none;
      }
      .form-item {
        display: block;
        input {
          width: 100%;
        }
      }
    }

    .help-block {
      color: $gray;
      padding-top: 1em;
    }
  }

  .alert-warning-list {
    display: inline-block;
    vertical-align: top;
    list-style-type: square;
  }
  .message-inner-separator {
    clear: both;
    margin-top: 4px;
    margin-bottom: 10px;
    height: 1px;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }

  // Datepicker.
  .ui-datepicker-calendar tbody td.highlight > a {
    color: black;
  }
  .dates-selected-schedule {
    margin-top: 1.25em;
  }
}
.page-user-bookings {
  .form-actions {
    clear: none;
    .btn {
      margin-bottom: 1em;
      @media screen and (min-width: #{$screen-xs-min}) {
        margin-left: 15px;
      }
      @media all and (min-width: $screen-xs-min) and (max-width: $screen-sm-min) {
        margin-left: 0;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    @include make-md-column(12);
    @include make-sm-column(12);
    @include make-xs-column(12);
    margin-bottom: 15px;
  }

  .container-inline-date {
    clear: none;
    font-size: .9em;
    margin-bottom: 30px;
    @include make-sm-column(3);
    @include make-lg-column(2);
    width: 100%;

    * {
      float: none;
    }

    .form-item {
      display: block;
      margin: 0;
      width: 100%;

      input {
        margin: 0;
        width: 100%;
      }
    }
  }
  .form-wrapper.form-group {
    margin-bottom: 0px;
  }
  .table-responsive {
    @include make-sm-column(12);
  }

  .form-actions.form-wrapper.form-group {
    margin-bottom: 1em;
  }

  .booking-status {
    background-color: $gray-dark;
    border-radius: 50%;
    display: inline-block;
    height: 18px;
    margin-right: 5px;
    vertical-align: text-top;
    width: 18px;

    &.booking-status-active {
      background-color: $brand-primary;
    }
    &.booking-status-canceled {
      background-color: $brand-danger;
    }
    &.booking-status-expired {
      background-color: $gray;
    }
    &.booking-status-rate {
      background-color: #eea236;
    }
  }
  .legend {
    float: left;
    list-style: none;
    margin: 0 0 20px;
    padding: 0 0 0 15px;

    li {
      align-items: center;
      display: inline-flex;
      font-size: .8em;
      font-weight: 600;
      padding: 0 8px 0 0;
      vertical-align: middle;
    }

    .booking-status {
      height: 14px;
      width: 14px;
    }
  }
  ul.pagination {
    padding-right: 15px;
  }

  .booking-user-actions {
    .btn.btn-sm {
      margin: 3px;
    }
  }
}
// Complaint
.bookings-new-complaint {
  @media screen and (min-width: #{$screen-md-min}) {
    .own-booking {
      margin-top: 40px;
    }
  }

}

// Icons.
.adapted-service-wrapper {
  margin-top: 5px;

  .icon-adapted-service {
    margin-right: 5px;
    width: 24px;
  }
}
