/**
 * Default Variables.
 *
 * Modify this file to provide default Bootstrap Framework variables. The
 * Bootstrap Framework will not override any variables defined here because it
 * uses the `!default` flag which will only set their default if not already
 * defined here.
 *
 * You can copy existing variables directly from the following file:
 * ./THEMENAME/bootstrap/assets/stylesheets/bootstrap/_variables.scss
 */

// Set the proper directory for the Bootstrap Glyphicon font.
$icon-font-path: '../bootstrap/assets/fonts/bootstrap/';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,600i,700,700i');

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base: #000;
$gray-darker: lighten($gray-base, 13%); // #212121
$gray: lighten($gray-base, 40%); // #555
$gray-light: lighten($gray-base, 80%); // #CCC

//$brand-primary: #e69e25;
$brand-primary: #007bc4;
$brand-danger: #a94442;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Global text color on `<body>`.
$text-color: #2A2A2A;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: XuntaSans, Verdana, Arial, sans-serif;

$font-size-base: 16px;
$font-size-large: ceil(($font-size-base * 1.25)); // ~20px
$font-size-small: ceil(($font-size-base * 0.6875)); // ~11px

$font-size-h1: floor(($font-size-base * 2.3)); // ~36px
$font-size-h2: floor(($font-size-base * 2)); // ~32px
$font-size-h3: ceil(($font-size-base * 1.5)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)); // ~20px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.75)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.875; // 30/16

//** By default, this inherits from the `<body>`.
$headings-font-weight: 700;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$border-radius-base: 5px;
$border-radius-large: 7px;
$border-radius-small: 3px;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.
//** Background color used for `.table-hover`.
$table-bg-active: transparent;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-default-color: #2A2A2A;

$btn-primary-color: #FFF;

//== Forms
//
//##

//** Text color for `<input>`s
$input-color: #2A2A2A;

$legend-color: #2A2A2A;

//== Grid system
//
//## Define your custom responsive grid.

//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Large screen / wide desktop
$container-large-desktop: (1172px + $grid-gutter-width);

//== Navbar
//
//##

// Basics of a navbar
$navbar-default-bg: rgba(255, 255, 255, 0.95);

// Navbar links
$navbar-default-link-color: $gray-base;
$navbar-default-link-hover-color: $brand-primary;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: $brand-primary;
$navbar-default-link-active-bg: transparent;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 0 20px;
$nav-link-hover-bg: transparent;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 0;
$breadcrumb-padding-horizontal: 0;
//** Breadcrumb background color
$breadcrumb-bg: none;

//== Type
//
//##

//** Page header border color
$page-header-border-color: transparent;
