/**
 * SECTION - Control panel.
 */
.page-control-panel {

  h1 {
    margin-bottom: 1em;
  }
  .admin {
    .compact-link {
      display: none;
    }

    .left, .right  {
      @include make-md-column(6);

      .admin-panel {
        .body {
          background: $gray-lighter;
          padding: 1em;

          dl {
            margin: 0;
          }
          dd {
            margin-bottom: 0.5em;
          }
        }
      }
    }
    .right {
      float: right;
    }
  }
  .reports-bookings {
    .field-date {
      width: 100%;
    }

    .container-inline-date {
      clear: none;

      * {
        float: none;
      }

      .form-item {
        display: block;
        margin: 0;

        input {
          margin: 0;
          width: 100%;
        }
      }
    }

  }
}