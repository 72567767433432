.page-virtual-canopy {

  @import 'virtual_canopy/busstop_position';
  @import 'virtual_canopy/messages';
  @import 'virtual_canopy/search_form';

  .result-busstop {
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 5px;
    line-height: 28px;
    margin: 30px 0;
    text-align: center;

    * {
      @media all and (min-width: $screen-md) {
        font-size: 24px;
        margin: 15px 0;
        display: block;
      }
    }
  }

  .text-small-plus {
    font-size: .8em;
    line-height: 1;
    padding-left: 3px;
    white-space: nowrap;
  }

  .schedules-results {
    table {
      border-collapse: unset;
      position: relative;

      &.results-mobile {
        tbody {
          tr {
            border-bottom: 1px solid $brand-primary;
            display: flex;
            flex-wrap: wrap;

            td, th {
              border-top: 1px solid #DDD;
              width: 50%;

              .expedition-type {
                margin: 7px 0;

                span {
                  display: inline-block;
                  white-space: nowrap;

                  &.school-integration {
                    vertical-align: middle;

                    img {
                      display: inline-block;
                      margin-top: -2px;
                    }
                  }

                  &.service-type {
                    margin-left: 10px;
                  }
                }
              }
            }

            td {
              font-size: 1.1em;
              text-align: right;
            }
          }
        }
      }

      td, th {
        &.results-item-time,
        &.results-header-time {
          max-width: 160px;
          text-align: center;
        }

        &.results-modal-link,
        &.results-header-modal {
          max-width: 122px;
          text-align: center;
        }
      }

      thead {
        background: white;
        position: sticky;
        top: 0;

        th {
          border-bottom: 1px solid $brand-primary;
        }
      }

      tbody tr {
        background-color: $table-bg-accent;

        &.expired {
          display: none !important;
        }

        &.realtime-row {
          background-color: #FFF;

          .time-text {
            font-size: 1.2em;
          }
        }

        th {
          padding: 14px 10px;
        }

        td {
          a {
            vertical-align: text-top;
          }

          .busstop-origin-destination {
            margin: 5px 0;

            svg {
              width: 10px;
            }
          }

          .bus-data:not(:empty) {
            border-top: 1px solid #ddd;
            display: inline-block;
            margin-top: 2px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  .legend {

    .busstop-position {
      margin-right: 5px;
    }

    li:not(:last-child) {
      margin-right: 10px;
    }
  }
}
