.page-header,
%title-large {
  background: transparent;
  border: 0;
  box-shadow: none;
  display: block;
  font-size: $font-size-h1 * 1.2;
  font-weight: 700;
  margin: 50px 0 100px;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;

  &:before {
    border-top: 5px solid $brand-primary;
    content: '';
    display: block;
    height: 0;
    margin: 0 auto 15px auto;
    width: 50px;
  }
}

.front .page-header {
  @extend .sr-only;
}

.panel-title,
%title-small {
  background: transparent;
  border: 0;
  box-shadow: none;
  display: block;
  font-size: $font-size-base * 1.7;
  font-weight: 700;
  margin: 30px 0 0 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;

  &:before {
    border-top: 4px solid $brand-primary;
    content: '';
    display: block;
    height: 0;
    margin: 0 auto 15px auto;
    width: 30px;
  }
}
