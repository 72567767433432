table,
.table {
  thead {
    tr {
      font-weight: 600;

      th {
        border: 0;
        line-height: 1.7;
        padding: 0 10px;
        vertical-align: bottom;
      }
    }
  }

  tbody {
    border-bottom: 1px solid $gray-light;
    border-top: 1px solid $brand-primary !important;
    font-size: .9em;

    tr {
      td, th {
        border-top: 1px solid #ddd;
        line-height: 1.3;
        padding: 7px 10px;
        vertical-align: middle;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
      }

      &:first-child {
        td, th {
          border-top: 1px solid $brand-primary;
        }
      }
    }
  }
}

.download-link-wrapper {
  font-size: 0.9em;
  font-weight: bold;
  margin: 0;
  text-align: right;
}
