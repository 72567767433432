.breadcrumb {
  list-style: none;
  margin: 10px 0 20px;
  padding: 0;
  line-height: 1.1;

  li {
    display: inline;
    font-size: .8em;

    a {
      color: $text-color;

      &:hover,
      &:focus {
        color: $brand-primary;
        text-decoration: underline;
      }

      &.active {
        color: darken($brand-primary, 15%);
      }
    }

    & + li {
      &:before {
        border-right: 1px solid $gray-light;
        border-top: 1px solid $gray-light;
        content: '';
        display: inline-block;
        height: 7px;
        margin: 0 13px 0 3px;
        padding: 0;
        position: relative;
        top: -1px;
        transform: rotate(45deg);
        width: 7px;
      }
    }

    &.active {
      &, a {
        color: $brand-primary;
      }
    }
  }

  .page-service-search & {
    margin-bottom: -10px;
    text-shadow: 0 0 0 transparentize(#FFF, 0.7);

    @media all and (max-width: $screen-sm) {
      padding-left: 15px;
    }
  }
}
