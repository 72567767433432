fieldset.results {
  border: 0;
  box-shadow: none;
  margin-top: 50px;

  .panel-heading {
    background-color: transparent;
    border: 0;
  }

  .panel-body {
    padding: 0;

    & > p:first-child {
      line-height: 1.7;
      margin: 0 auto 50px;
      padding: 0 ($grid-gutter-width / 2);
      text-align: center;
    }

    table {
      margin-bottom: 20px;
      position: relative;

      thead {
        tr {
          &:first-child {
            font-size: 1.1em;
            font-weight: 700;
            text-transform: uppercase;

            th {
              &.section-separator {
                padding-left: 30px;
                position: relative;

                &:before {
                  border-left: 1px solid $brand-primary;
                  content: '';
                  display: block;
                  height: calc(200% - 10px);
                  left: 5px;
                  position: absolute;
                  top: 2px;
                  width: 0;
                }
              }
            }
          }

          &:last-child {
            th {
              padding-bottom: 10px;

              &.arrive-busstop,
              &.more-info-lvl-2 {
                padding-left: 30px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          &:hover .alert-service-icon svg {
            opacity: 0.8;
          }
          cursor: pointer;

          td {
            &.cel-spacing {
              padding-left: 30px;
            }
            &:nth-child(-n + 3) {
              width: 1%;
            }
            img.school-integration-image {
              height: 20px;
            }

            &:last-child {
              box-sizing: content-box;
              font-size: 1.2em;
              padding: 7px;
              text-align: right;
              width: 1.2em;

              a {
                display: block;
                margin: 3px 0;
              }
            }

            .use-ajax {
              .ajax-progress-throbber {
                z-index: 1;
                align-items: center;
                background-color: transparentize(#fff, 0.2);
                bottom: 0;
                display: flex;
                font-size: 1.5em;
                justify-content: center;
                left: 0;
                position: absolute;
                right: 0;
                text-align: center;
                top: 1px;

                i {
                  padding: 40px;
                  transform-origin: center 49.5%;
                }
              }
            }
            .service-type-wrapper-table {
              display: flex;
              span:first-child {
                float: right;
              }
              span:last-child {
                float: left;
              }
            }

            &.service-discount-wrapper {
              @include youth-card-icon;
            }
          }
        }

        .alert-service-icon svg {
          height: 20px;
          width: 15px;
          vertical-align: bottom;
          margin-left: 15px;
          color: #b81414;
          opacity: 0.2;
        }

        .download-link-pdf.bonuses-wrapper {
          border-top: 0;
          float: none;
          text-align: left;
        }
      }

      &.results-mobile {
        tbody {
          border-bottom: 0;
          display: table;
          position: relative;

          &:last-child {
            border-bottom: 1px solid #777;
          }

          tr {
            &:first-child {
              & > * {
                border-top: 1px solid $brand-primary;
              }
            }

            &:nth-child(3) {
              td {
                font-size: 1em;

                img {
                  box-sizing: content-box;
                  height: 30px;
                  padding: 8px 15px 8px 0;
                }

                .service-type-wrapper {
                  display: inline-block;
                  vertical-align: middle;
                  line-height: 0;
                  margin: 7px 0;

                  &.service-type-demand {
                    border-color: $brand-primary;
                  }

                  span {
                    display: inline-block;
                    vertical-align: middle;

                    &:last-child {
                      padding: 0 10px 0 3px;
                      line-height: 1;
                      font-size: 0.8em;
                    }
                  }
                }

                .service-discount-wrapper {
                  display: flex;
                  justify-content: right;

                  .icon {
                    margin-right: 10px;
                    @include youth-card-icon;
                  }
                }
              }
            }

            td, th {
              line-height: 1.5;
              padding: 8px;
              width: 100%;

              &:nth-child(3) {
                font-size: 1.1em;
                text-align: right;
              }
            }

            th {
              width: 1%;
            }
          }
        }
        .alert-service-icon {
          padding: 8px;
          svg {
            vertical-align: middle;
            margin-left: 15px;
            color: #b81414;
            opacity: 0.8;
          }
        }

        .download-link-pdf.bonuses-wrapper {
          font-size: 80%;
          margin-top: 0;
          text-align: right;
        }
      }
      td.expedition-settings {
        padding: 7px 5px;
      }
    }

    .pager-wrapper {
      text-align: right;
      @include make-md-column(7, 0);

      .pager-message-wrapper,
      .pager-results-per-page-wrapper {
        display: inline-block;
        font-size: .8em;
        vertical-align: baseline;
      }

      .pager-message-wrapper {
        margin-bottom: 0;

        .placeholder {
          font-style: normal;
        }
      }

      .pager-results-per-page-wrapper {
        margin-bottom: 0;
        margin-left: 50px;

        & > * {
          display: inline-block;
          margin: 0;
          vertical-align: middle;
        }

        .form-item-results-per-page {
          margin: 0 10px;

          .select2 {
            border-color: #bababa;
            border-radius: 5px;
            color: #ddd;
            font-size: 1em;
            height: auto;
            line-height: 1em;
            padding: 8px 10px;

            .select2-selection {
              height: auto;

              .select2-selection__rendered {
                color: #2a2a2a;
                height: 1em;
                line-height: 1.1;
                padding-right: 15px;
              }

              .select2-selection__arrow {
                height: 100%;
                position: absolute;
                right: 13px;
                top: 0;
                width: 0;
                color: #2a2a2a;

                b, strong {
                  border-top-color: #bababa;
                }
              }
            }
            &.select2-container--focus {
              background: $gray-darker;
              .select2-selection {
                .select2-selection__rendered {
                  color: #fff;
                }
                .select2-selection__arrow {
                  color: #fff;
                }
              }
            }
            &.select2-container--open {
              background: #fff;
              .select2-selection {
                .select2-selection__rendered {
                  color: #2a2a2a;
                }

                .select2-selection__arrow {
                  color: #2a2a2a;
                }
              }
            }
          }
        }
      }

      .pager-link-wrapper {
        font-size: 0;
        margin-top: 30px;

        .btn {
          background-color: #fff;
          border: 1px solid #bababa;
          border-radius: 0;
          box-shadow: none;
          color: #2a2a2a;
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 30px;
          min-width: 34px;
          padding: 0 8px;
          position: relative;
          width: auto;

          & + .btn {
            border-left: 0;
          }

          &:first-child {
            border-bottom-left-radius: $btn-border-radius-base;
            border-top-left-radius: $btn-border-radius-base;
          }

          &:last-child {
            border-bottom-right-radius: $btn-border-radius-base;
            border-top-right-radius: $btn-border-radius-base;
          }
          &.active {
            background-color: $pager-color-accent;
            color: #fff;
            outline: none;
          }
          &:hover {
            background-color: fade_out($pager-color-accent, 0.3);
            color: $text-color;
            outline: none;
          }
          &:focus {
            background-color: $gray-darker;
            color: #fff;
          }

          .ajax-progress-throbber {
            background-color: transparentize(#fff, 0.2);
            bottom: 0;
            left: 0;
            line-height: 32px;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            z-index: 10;

            .glyphicon {
              color: $pager-color-accent;
              transform-origin: center 45%;
            }
          }

          &.progress-disabled {
            opacity: 1;
          }
        }
      }
    }

    @media all and (max-width: $screen-xs) {
      padding: 15px 0;
    }
  }

  .results-rates {
    margin-bottom: -20px;
    text-align: right;

    > div {
      display: inline-block;
      margin-left: 15px;
    }
  }
}

.service-type {
  background-color: $gray-dark;
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  vertical-align: text-top;
  width: 15px;

  &.service-type-demand,
  &.service-type-consolidated {
    background-color: $brand-primary;
  }
}
.legend {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  li {
    align-items: center;
    display: inline-flex;
    font-size: .8em;
    font-weight: 600;
    padding: 0 2px;
    vertical-align: middle;

    .service-type {
      height: 14px;
      vertical-align: text-bottom;
      width: 14px;
    }

    img {
      height: 25px;
      margin-right: 5px;
    }
  }
}
.table .service-type {
  display: block;
}
.select2-dropdown.results-per-page {
  border: 1px solid #bababa;
  border-top: 0;

  .select2-results {
    .select2-results__options {
      .select2-results__option {
        color: #2a2a2a;
        font-size: .9em;
        height: auto;
        line-height: .9em;
        padding: 8px 10px !important;
        text-align: center;

        &.select2-results__option--highlighted,
        &[aria-selected=true] {
          background-color: $gray-lighter;
          color: $gray;
        }
      }
    }
  }
}
.view-exp-link {
  position: inherit !important;
  &:focus {
    width: auto;
    height: auto;
    @extend .btn, .btn-default, .btn-sm;
  }

}
