.busstop-position {
  display: inline-block;

  &.busstop-position-start {
    span:nth-child(1) {
      height: 8px;
      width: 8px;
    }

    span:nth-child(3) {
      height: 4px;
      width: 4px;
    }
  }

  &.busstop-position-end {
    span:nth-child(5) {
      height: 8px;
      width: 8px;
    }

    span:nth-child(3) {
      height: 4px;
      width: 4px;
    }
  }

  span {
    background: transparentize($gray, 0.5);
    border-radius: 10px;
    display: inline-block;
    height: 4px;
    margin: 0 3px;
    vertical-align: middle;
    width: 4px;

    &:nth-child(3) {
      height: 8px;
      width: 8px;
    }
  }
}
