body {
  font-weight: 400;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
}

.container {
  @media #{$tablet} {
    padding-left: $container-padding-sm;
    padding-right: $container-padding-sm;
  }
  @media #{$normal} {
    padding-left: $container-padding-lg;
    padding-right: $container-padding-lg;
  }
}

.main-container {
  & > .container {
    background: #fff;
    padding-bottom: 60px;
  }
}

a {
  &:focus {
    outline: 1px dotted;
  }
}

.field ul {
  list-style: none;

  li {
    &:before {
      content: '';
      width: .5em;
      height: .5em;
      vertical-align: middle;
      display: inline-block;
      background-color: $brand-primary;
      margin-right: 10px;
      margin-left: -20px;
      margin-top: -1px;
    }
  }
}

.text-small {
  font-size: 0.9em;
}
.float-left {
  float: left;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  .border {
    border-right: 1px solid $table-border-color;
  }
}

#modal-info-label {
  font-weight: bold;
  font-size: 20px;
}

