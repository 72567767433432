.header-bottom {
  position: relative;
  text-align: center;
}

#block-w-tpgal-common-w-tpgal-common-warnings {
  display: inline-block;
  margin: 1rem;
  text-align: center;
  vertical-align: middle;

  a {
    background: $brand-primary;
    border-radius: 100px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
    color: #FFF;
    display: inline-block;
    height: 200px;
    line-height: 1.5em;
    margin: -25px auto 25px;
    padding: 40px 40px 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: all .2s;
    width: 200px;
    z-index: 11;

    @media #{$mobile} {
      font-size: 1.3rem;
      height: 150px;
      padding-top: 25px;
      width: 150px;
    }

    @media #{$mobile} and (min-width: #{295px}) {
      margin-left: -50px;
    }

    @media #{$normal} {
      bottom: -100px;
      position: absolute;
      right: 0;
      transform: scale(0.8);
    }
    @media #{$wide} {
      bottom: -80px;
      right: 10px;
      transform: scale(1);
    }

    &:hover,
    &:active {
      background: darken($brand-primary, 15%);
      text-decoration: none;

      svg {
        opacity: 0.5;
      }
    }

    span {
      display: block;
      margin-bottom: 0.5em;

      &:nth-child(2) {
        font-size: 0.9em;
      }
    }

    svg {
      transition: all .2s;
    }
  }
}


.view-warnings {
  .views-row {
    border-bottom: 1px dotted $gray-light;
    margin-bottom: 1em;
    padding-bottom: 1em;

    &.views-row-last {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
