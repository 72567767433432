/**
 * COMPONENTS - Datepicker.
 */
#ui-datepicker-div, .ui-datepicker.ui-widget {
  background: $brand-primary;
  border: 1px solid darken($brand-primary, 5%);
  font-family: $font-family-base;
  z-index: 2 !important;

  .ui-datepicker-header {
    background: none;
    border: 0;
    color: #FFF;

    a {
      background: none;
      border: 0;

      &.ui-datepicker-prev {
        left: 2px;
      }
      &.ui-datepicker-next {
        right: 2px;
      }
      &.ui-state-hover {
        top: 2px;
      }
    }
  }

  thead {
    th {
      color: #FFF;
    }
  }

  tbody {
    border: 0 !important;
  }

  td {
    border: 0;

    span {
      border: 0;
    }
    a {
      border: 0;

      &.ui-state-default {
        background: #fff;
      }
    }
  }
}
