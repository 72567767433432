
@mixin youth-card-icon {
  svg {
    width: 20px;
    height: 20px;

    rect:not(:first-child),
    path {
      fill: #999999
    }
  }
}

.legend-youth-card {
  display: flex;
  @include youth-card-icon;

  svg {
    width: 30px;
    height: 24px;
    margin-right: 5px;
  }

}
