.faq-content,
.page-node {

  .faq,
  .main-container {
    .block-open-readspeaker {
      float: left;
    }

    .node-featured-content {
      margin-bottom: 50px;
    }

    .view-node-attachments {
      .table-responsive {
        .table {
          thead {
            tr {
              th {
                &.views-field-field-files {
                  padding-left: 50px;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 12px 15px;
                white-space: normal;

                &.views-field-field-files {
                  width: 100%;

                  .file {
                    align-items: center;
                    display: flex;

                    i, .svg-inline--fa {
                      display: inline-block;
                      flex-shrink: 0;
                      font-size: 1.7em;
                      vertical-align: middle;
                      width: 30px;
                    }

                    a {
                      display: inline-block;
                      flex-grow: 1;
                      font-size: 1.1em;
                      padding-left: 5px;
                      vertical-align: middle;
                    }
                  }
                }

                &.views-field-filesize,
                &.views-field-extension {
                  font-size: .9em;
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }

    h2.block-title {
      @extend .panel-title;
      font-size: $font-size-base * 1.5;
      margin-bottom: 20px;
    }
  }

  @media all and (max-width: $screen-sm) {
    .faq,
    .main-container {
      .node-featured-content {
        .service-links {
          top: -10px;
        }
      }

      .view-node-attachments {
        .table-responsive {
          border: 0;

          .table {
            thead {
              display: none;
            }

            tbody {
              tr {
                td {
                  border-top: 0;
                  display: block;
                  padding: 7px 10px;

                  &.views-field-field-files {
                    padding-bottom: 0;
                  }

                  &.views-field-filesize {
                    padding: 0 0 7px 45px;
                    text-align: left;
                  }

                  &.views-field-extension {
                    display: none;
                  }
                }

                & + tr {
                  border-top: 1px solid #ccc;
                }
              }
            }
          }
        }
      }
    }
  }
}
