.view-node-gallery {
  .view-content {
    @include make-row(10px);

    .views-row {
      @include make-xs-column(12, 10px);
      @include make-sm-column(6, 10px);
      @include make-md-column(4, 10px);
      margin-bottom: 10px;

      a {
        box-shadow: none;
        display: block;
        position: relative;
        transition: all .3s;
        backface-visibility: hidden;

        img {
          width: 100%;
        }

        &:after {
          background-color: transparentize(#000, 0.6);
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: all .3s;
        }

        &:hover,
        &:active {
          box-shadow: 0 2px 10px transparentize($gray, 0.1);
          transform: scale(1.01);

          &:after {
            opacity: 0 !important;
          }
        }
      }
    }

    &:hover {
      .views-row {
        a {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  @media all and (max-width: $screen-sm) {
    .view-content {
      .views-row {
        a {
          &:hover {
            transform: none;
          }
        }
      }

      &:hover {
        .views-row {
          a {
            &:after {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}