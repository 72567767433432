/**
 * COMPONENTS - Tabs.
 */

.nav-tabs {
  > li {
    margin-bottom: 0.2em;
    text-align: center;
    width: 100%;

    @media #{$tablet} {
      margin-bottom: -1px;
      width: auto;
    }

    &:not(.active) {
      a {
        color: #fff;
        background: #007bc4;
      }
    }

    a {
      border-radius: 0;
      line-height: 3em;
    }
  }
}
