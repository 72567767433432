.update-service-files {
  margin-top: 50px;

  .indented-container {
    margin-left: 50px;
  }
  .select2 {
    height: auto;
  }
}

