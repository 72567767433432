.ui-autocomplete-input {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMjgnIGhlaWdodD0nMTI4Jz48Y2lyY2xlIGN4PSc2NCcgY3k9JzY0JyByPSc0MCcgZmlsbD0nd2hpdGUnIHN0cm9rZT0ncmdiKDIyMCwgMjIwLCAyMjApJyBzdHJva2Utd2lkdGg9JzEwJy8+PC9zdmc+);
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: auto 60%;

  &.ui-autocomplete-loading {
    background-image: url('../images/icons/throbber.gif');
  }
}

.ui-autocomplete.ui-menu {
  border: 1px solid transparentize($brand-primary, 0.6);
  margin-top: -2px;
  overflow: hidden;
  padding: 0;

  & * {
    font-family: $font-family-sans-serif !important;
  }

  .ui-menu-category {
    padding: 10px 15px 2px 15px;
    font-size: 0.8em;
    font-weight: bold;
    color: #2A2A2A;
    text-transform: uppercase;
  }

  .ui-menu-item {
    a {
      border: 0 !important;
      color: $gray;
      cursor: pointer;
      font-size: 0.8em;
      margin: 0 !important;
      padding: 5px 30px 5px 20px;
      transition: all .2s;
      white-space: nowrap;

      i, .svg-inline--fa {
        font-size: 1.1em;
      }

      span {
        display: inline-block;
        padding-left: 10px;
        transition: inherit;
        vertical-align: top;
        white-space: normal;
      }

      &.ui-state-focus {
        background: transparentize($brand-primary, 0.95);
        color: $brand-primary;
        font-weight: 600;

        span {
          padding-left: 15px;
        }
      }
    }
  }
}

.ui-autocomplete {
  max-height: 300px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}