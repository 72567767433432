.item-target {
  @media all and (max-width: $screen-lg) {
    margin-top: 30px;
  }
  @media #{$tablet} {
    height: 161px;
  }

  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background-color: $btn-primary-color;
  transition: all 0.2s;
  display: table;
  width: 100%;

  &:hover {
    border: 1px solid rgba(0, 123, 166, 0.5);
    background:  $brand-primary;
  }
  &:hover .target-btn, &:hover {
    color: $btn-primary-color;
  }
  &:hover svg path, &:hover {
    fill: $btn-primary-color;
  }

  .target-btn {
    color: $text-color;
    text-decoration: none;
    display: table-cell;
    vertical-align: middle;

    &:hover {
      color: $btn-primary-color;
    }

    .icon-target {
      display: inline-block;
      vertical-align: text-top;
      width: 20%;
      margin-right: 5%;
      text-align: center;
      svg {
        color: $brand-primary;
        font-size: 3em;
      }
    }

    .text-target {
      display: inline-block;
      vertical-align: top;
      width: 73%;
      .title-target {
        text-transform: uppercase;
        font-weight: bold;
      }
      .description-target {
        font-size: 0.9em;
      }
    }
  }
}

.map-tab {
  text-align: center;
  margin: 2em auto;
}


