/**
 * SECTION - Control panel - Services.
 */
.page-control-panel-services-search,
.page-control-panel-services-bookings,
.page-control-panel-services-blacklist,
.page-control-panel-services-departures,
.page-control-panel-services-alerts,
.page-control-panel-services-complaints,
.page-control-panel-services-expeditions,
.page-control-panel-reports-upload-list,
.page-booking {

  .collapsible-adv-filter {
    .fa-angle-right {
      transform: rotate(0);
      transition: all 0.1s;
    }

    &[aria-expanded=true] {
      .fa-angle-right {
        transform: rotate(90deg);
        transition: all 0.1s;
      }
    }
  }

  // Form group.
  .form-group {
    margin-bottom: 1em;
  }

  // Date fields.
  .container-inline-date {
    .date-padding {
      float: none;
    }

    .form-item {
      margin-right: 0;
      padding: 0;

      .form-item {
        margin-bottom: 0;
      }

      input {
        width: 100%;
      }
    }
  }

  // Actions.
  .form-actions {
    > * {
      display: block;
      text-align: center;
      width: 100%;

      @media #{$tablet} {
        display: inline-block;
        width: auto;
      }
    }

    .btn-primary {
      margin-bottom: 1em;

      @media #{$tablet} {
        float: right;
        margin-left: 1em;
      }
    }
  }

  ul.action-links.download-csv-link {
    margin: 0 0 0 0.5em;
  }

  p.table-title {
    text-align: center;
    width: 100%;
    font-size: 1.4em;
    font-weight: bold;
  }

  .search-prev-date-ret,
  .search-prev-date {
    float: left;
    color: #007bc4;
  }

  .search-next-date-ret,
  .search-next-date {
    float: right;
    color: #007bc4;
  }

  .front-date-control {
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 15px;
    line-height: 28px;
  }

  ul.pagination {
    margin-bottom: 15px;
  }

  .download-link-pdf {
    font-weight: bold;
    text-align: right;
    margin: 0;
    clear: both;
    border-top: 1px dashed #bbb;
    padding-top: 0.5em;
    font-size: 0.9em;
  }

  .booking-status {
    background-color: $gray-dark;
    border-radius: 50%;
    display: inline-block;
    height: 18px;
    margin-right: 5px;
    vertical-align: text-top;
    width: 18px;

    &.booking-status-active {
      background-color: $brand-primary;
    }

    &.booking-status-canceled {
      background-color: $brand-danger;
    }

    &.booking-status-expired {
      background-color: $gray;
    }

    &.booking-status-rate {
      background-color: #eea236;
    }
  }

  .legend {
    float: left;
    list-style: none;
    margin: 0 0 20px;

    li {
      align-items: center;
      display: inline-flex;
      font-size: .8em;
      font-weight: 600;
      padding: 0 8px 0 0;
      vertical-align: middle;
    }

    .booking-status {
      height: 14px;
      width: 14px;
    }
  }

  #autocomplete-place-text {
    font-size: 13px;
    height: 30px;
    clear: none;
    width: 200px;
    padding: 0 10px;
    font-family: "Montserrat", Verdana, Arial, sans-serif;
    color: #444;
  }

  .autocomplete-list-places {
    z-index: 1700;

    li a {
      white-space: normal !important;
    }
  }

  .link-return-cp {
    font-size: 0.9em;
    margin-top: -2em;
    margin-bottom: 3em;
  }

  .search-box-modal {
    /* IE10+ CSS styles */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 252px;
      #search-place-btn {
        padding-top: 4px !important;
        padding-bottom: 3.5px !important;
      }
    }
  }

  // Expand input group link.
  .input-group {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    .input-group-addon {
      padding: 0;

      a {
        padding: 12px 12px;
      }
    }

    #search-place-btn {
      &:hover, &:focus, &:active {
        color: #00425a !important;
      }
    }
  }
}

.page-control-panel-services-bookings {

  #advanced-filter {
    background: #f9f9f9;
    border: 1px dotted #ccc;
    padding: 1em;
  }

  th {
    white-space: nowrap;

    .icon-after {
      margin-left: 5px;
    }
  }

  .action-links {
    li > a {
      width: 100%;
      display: block;
      margin-top: 0;
      @media #{$normal} {
        margin-top: 0;
      }
      @media #{$tablet} {
        width: auto;
        display: inline-block;
        &:last-child {
          margin-top: 1em;
        }
      }
    }
  }
}

.page-control-panel-services-bookings-new,
.page-control-panel-services-blacklist-new,
.page-control-panel-services-expeditions-edit,
.page-control-panel-services-departures-new,
.page-control-panel-services-alerts-new {
  // Fieldset.
  .panel {
    border: 0;
    box-shadow: none;
    margin-bottom: 2em;

    .panel-heading {
      font-size: 1em;

      .fieldset-legend {
        font-size: 1em;
        margin-top: 0;
        text-align: left;

        &:before {
          display: none;
        }
      }
    }
  }

  .alert-warning-list {
    display: inline-block;
    vertical-align: top;
    list-style-type: square;
  }

  .message-inner-separator {
    clear: both;
    margin-top: 4px;
    margin-bottom: 10px;
    height: 1px;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }

  .expedition-name-edit {
    margin-bottom: 2em;

    svg {
      margin: 0 2em;
    }
  }

  .line-name-edit {
    h2 {
      margin: 10px 0 7px 0;
    }
  }

}

.page-control-panel-services-bookings-new {
  .form-actions {
    > button {
      display: block;
      width: 100%;
      @media #{$normal} {
        display: inline-block;
        width: auto;
      }

      &:last-child {
        margin-top: 1em;
        @media #{$normal} {
          margin-top: 0;
        }
      }
    }
  }
}

.page-control-panel-services-alerts-new {
  .container-inline-date {
    display: block;

    > .form-item {
      display: block;

      .date-padding {
        @include make-row();

        .help-block {
          display: none;
        }
      }
    }

    .form-item-startdate-date,
    .form-item-enddate-date {
      @include make-md-column(8);
    }

    .form-item-startdate-time,
    .form-item-enddate-time {
      @include make-md-column(4);
    }
  }
}

/**
 * Departure new (table pagination).
 */
.page-control-panel-services-departures-new {
  .dataTables_paginate.paging_full_numbers {
    float: right;
    margin: 5px 0 0;

    .ellipsis,
    .paginate_button {
      text-decoration: none;

      &.first {
        border-left: 1px solid #bababa !important;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-left: 0;
      }

      &.last {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:hover,
      &:focus {
        z-index: 3;
        cursor: default;
        border-color: #007bc4;
        background-color: rgba(0, 154, 217, 0.7);
        color: #2A2A2A !important;
      }

      &.current {
        background-color: #009ad9 !important;
        color: #2A2A2A;
        outline: none;
      }

      background-color: #FFF;
      border-right: 1px solid #bababa !important;
      border-bottom: 1px solid #bababa !important;
      border-top: 1px solid #bababa !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #bababa;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 30px;
      min-width: 34px;
      padding: 4px 12px;
      -webkit-transition: all .2s;
      transition: all .2s;
      width: auto;
    }

    span.ellipsis {
      &:hover {
        color: #ccc;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
      }
    }
  }
}

/**
  Control panel with modal forms (Alters and departure exceptions).
 */
.page-control-panel-services-alerts-new,
.page-control-panel-services-departures-new {
  span.select2-container {
    z-index: 20000;
  }

  .input-group .select2 {
    z-index: 2;
  }

  .format-modal-btn {
    height: 44px;
    padding: 0;
    width: 100%;
    min-width: 100%;
    &.progress-disabled {
      svg {
        display: none;
      }
    }
  }

  .format-add-btn {
    margin-top: 35px;
  }

  .alert-separator,
  .departure-separator {
    border-top: 1px dashed #ccc;
    margin-top: 1em;
    padding-top: 1em;
  }

  .select-line-description {
    color: $gray;
    font-size: 0.75em;
  }

  .add-some-lines {
    color: $gray;
    font-size: 0.75em;
  }

  .search-lines-wrapper,
  .search-expedition-wrapper {
    padding: 1em 1em 0 1em;
  }


  .search-input-modal {
    padding-right: 15px;
    @media (min-width: $screen-md-min) {
      padding-right: 0;
    }
  }

  .format-search-btn {
    margin-top: 0;
    @media (min-width: $screen-md-min) {
      margin-top: 35px;
    }
    button.progress-disabled {
      svg {
      display: none;
      }
    }
  }

  .add-expedition-wrapper {
    text-align: center;

    button {
      min-width: auto;
      padding: 3px 10px;
    }
  }

  .expedition-table {
    margin-top: 1em;

    td {
      font-size: smaller;
    }
  }
}

.page-control-panel-services-expeditions-edit {
  .expedition-separator {
    border-top: 1px dashed #ccc;
    margin-top: 1em;
    padding-top: 1em;
  }

  h2 {
    margin-bottom: 0.3em;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1em;
  }
}

/**
 * Exploitation page (Upload).
 */
.page-control-panel-reports-operators-send-exploitation-data {
  .explotation-download {
    margin-bottom: 30px;
  }
}

/**
 * Exploitation page (Upload list).
 */
.page-control-panel-reports-upload-list {
  .upload-list-table {
    thead tr {
      th:nth-child(1) {
        width: 20%;
      }

      th:nth-child(2) {
        width: 10%;
      }

      th:nth-child(3) {
        width: 35%;
      }

      th:nth-child(4) {
        width: 35%;
      }
    }
  }

  .download-loaded-file {
    margin-right: 6px;
  }
}
