#block-w-tpgal-common-w-tpgal-common-suggestions {
  display: inline-block;
  margin: 1rem;
  text-align: center;
  vertical-align: middle;

  a {
    background: #e1f1f9;
    border-radius: 100px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
    color: $brand-primary;
    display: inline-block;
    height: 200px;
    line-height: 1.5em;
    margin: -25px auto 25px;
    padding: 40px 40px 0;
    text-align: center;
    text-transform: uppercase;
    transition: all .2s;
    width: 200px;
    z-index: 10;

    @media #{$mobile} {
      font-size: 1.1rem;
      height: 120px;
      line-height: 1.2em;
      padding: 25px 25px 0;
      position: relative;
      right: 10px;
      width: 120px;
    }

    @media #{$normal} {
      bottom: -105px;
      position: absolute;
      right: 130px;
      transform: scale(0.7);
    }
    @media #{$wide} {
      bottom: -105px;
      right: 160px;
      transform: scale(0.8);
    }

    &:hover,
    &:active {
      background: lighten(#e1f1f9, 15%);
      text-decoration: none;

      svg {
        opacity: 0.5;
      }
    }

    span {
      display: block;
      margin-bottom: 0.5em;
      font-weight: bold;
    }

    svg {
      transition: all .2s;
      transform: scale(1.4);
    }
  }
}
