/**
 * REGION - Footer.
 */

.pre-footer {
  border-top: 8px solid $brand-primary;
  background: $gray-darker;
  color: #fff;
  padding-bottom: 70px;
  padding-top: 50px;

  .region-pre-footer {
    @include make-row();
  }
}

.footer {
  background: $brand-primary;
  border: 0;
  color: #fff;
  font-size: $font-size-small;
  margin-top: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
}
