.faq {
  .faq-question-answer {
    margin-left: 40px;
    padding: 30px 0;

    .faq-question,
    .faq-answer {
      padding-left: 0;
      padding-top: 0;

      .service-links {
        top: 0;
      }
    }

    .faq-question {
      a {
        color: $text-color;
        display: block;
        font-size: 1.2em;
        position: relative;
        transition: all .3s;

        &:before {
          align-items: center;
          border: 1px solid;
          border-radius: 50%;
          box-sizing: content-box;
          color: $brand-primary;
          content: '+';
          display: flex;
          font-size: 20px;
          font-weight: 300;
          justify-content: center;
          line-height: 22px;
          margin-right: 10px;
          position: absolute;
          right: 100%;
          text-align: center;
          top: 50%;
          transform: translateY(-50%);
          transition: all .3s, padding-bottom 0s, line-height 0s;
          width: 22px;
        }

        &:hover,
        &:focus {
          color: $brand-primary;
          text-decoration: none;

          &:before {
            background-color: $brand-primary;
            border-color: $brand-primary;
            color: #FFF;
          }
        }
      }

      a[aria-expanded="true"] {
        color: $brand-primary;
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.4em;

        &:before {
          background-color: $brand-primary;
          border-color: $brand-primary;
          color: #FFF;
          content: '-';
          line-height: 19px;
          padding-bottom: 3px;
        }

        &:hover {
          &:before {
            background-color: lighten($brand-primary, 15%);
            border-color: lighten($brand-primary, 15%);
          }
        }
      }
    }

    & + .faq-question-answer {
      border-top: 1px solid transparentize($brand-primary, 0.7);
    }
  }
}

.page-faq {
  .col-lg-3 {
    @media (min-width: $screen-lg + 1) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .item-target {
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        .icon-target {
          svg {
            .color-opacity,
            circle {
              fill: #007bc4
            }
            .white-opacity {
              fill: white;
            }
          }
        }
      }

      a.target-btn {
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .icon-target {
          width: 40%;

          svg {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }
}


