/**
 * COMPONENTS - Forms.
 */

%form-item-loader {
  background-color: transparentize(#fff, 0.2);
  background-image: url('../images/icons/throbber.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 70%;
  bottom: 0;
  content: '';
  left: 0;
  opacity: .9;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 10;
}
.form-actions {
  text-align: right;
}
.form-group {
  margin-bottom: 30px;
}
.form-item {
  position: relative;

  &.form-type-textfield {
    .ajax-progress {
      bottom: 4px;
      position: absolute;
      right: 10px;
    }
  }
}
.form-control {
  font-size: $font-size-forms;
  padding: 6px 15px;
  position: relative;

  &.form-textarea {
    min-height: 80px;
  }

  &::placeholder {
    color: $text-color;
    opacity: .8;
    transition: all .2s;
  }

  &:hover,
  &:focus {
    &::placeholder {
      opacity: .2;
    }
  }

  &:focus,
  &:focus-within {
    border-color: $brand-primary;
    box-shadow: none;
  }

  &.error {
    border-color: $brand-danger;
  }
}
.btn {
  font-weight: 700;
  min-width: 220px;
  padding: 15px 70px 12px;
  position: relative;
  text-transform: uppercase;
  transition: all .2s;

  &.btn-sm {
    min-width: auto;
    padding: 5px 10px;
  }

  &.loading {
    pointer-events: none;

    &:before {
      @extend %form-item-loader;
      background-size: auto 50%;
      border-radius: inherit;
    }
  }
}
.input-group .select2:not(:first-child):not(:last-child) {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.select2 {
  @extend .form-control;

  &.select2-container--disabled {
    background-color: $gray-lighter;
    cursor: not-allowed;

    .select2-selection__rendered {
      cursor: not-allowed;
    }
  }

  .selection {
    .select2-selection {
      background-color: transparent;
      border: 0;

      .select2-selection__rendered {
        padding-left: 0;
      }

      .select2-selection__arrow {
        height: 40px;
      }
    }
  }
}
select {
  &.loading {
    & + .select2 {
      &:before {
        @extend %form-item-loader;
      }
    }
  }
}
.select2-container {
  .select2-dropdown {
    border: 1px solid transparentize($brand-primary, 0.6);
    border-radius: 4px;
    overflow: hidden;

    .select2-search {
      .select2-search__field {
        @extend .form-control;
      }
    }

    .select2-results {
      .select2-results__options {
        .select2-results__option {
          color: $gray;
          cursor: pointer;
          font-size: 0.8em;
          padding: 5px 20px;
          transition: all .2s;

          &.select2-results__option--highlighted {
            background: transparentize($brand-primary, 0.95);
            border: 0;
            color: $brand-primary;
            font-weight: 600;
            padding-left: 25px;
          }

          &.select2-results__message {
            font-size: .8em;
            padding: 5px 15px;
          }
        }
      }
    }
  }
}
.form-required {
  color: $brand-primary;
}
label.error {
  animation: error-blink .13s ease-in 0s alternate 3 both;
  background-color: $brand-danger;
  border-radius: 3px;
  color: #fff;
  font-size: $font-size-base / 1.5;
  font-weight: 600;
  left: 0;
  line-height: 1.2;
  margin: 0;
  margin-left: 32px;
  max-width: calc(100% - #{$grid-gutter-width} - (16px * 2));
  min-width: 100px;
  opacity: 1;
  padding: 3px 5px 2px;
  position: absolute;
  top: calc(100% - 6px);
  transition: all .2s;
  z-index: 4;

  @keyframes error-blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .container-inline-date & {
    margin-left: 17px;
  }
}
.panel-default > .panel-heading {
  color: $legend-color;
}


.form-type-select {
  .input-group {
    display: block;
    overflow: hidden;

    .input-group-addon {
      border: 0;
      content: "";
      height: 0;
      padding: 0;
      width: 0;

      > div {
        background: rgba(255, 255, 255, 0.75);
        border: 0;
        border-radius: 0;
        height: 100%;
        left: 0;
        padding: 12px;
        position: absolute;
        text-align: right;
        top: 0;
        width: 100%;
        z-index: 2;
      }
    }
  }
}

.form-type-file {
  label {
    border: 1px solid $gray;
    border-radius: 5px;
    cursor: pointer;
    padding: 15px 70px 12px;
    transition: all 0.3s;

    &:hover {
      background: $gray-lighter;
    }
  }

  &.form-disabled {
    label {
      background: #f3f3f3;
      border-color: #999;
      color: #999;
      cursor: not-allowed;
    }
  }

  input {
    display: none;
  }
}
