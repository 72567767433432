.view-featured-content {
  .view-content {
    position: relative;

    .views-row {
      padding: 40px 0;
      position: relative;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      & + .views-row {
        &:before {
          border-bottom: 1px solid $brand-primary;
          content: '';
          display: block;
          height: 0;
          min-width: 200px;
          opacity: .6;
          position: absolute;
          top: 0;
          width: 30%;
        }
      }

      .txt-left,
      .txt-right {
        display: flex;
        width: 100%;
        flex: 0 0 100%;
        align-items: center;
      }

      .txt-left {
        justify-content: flex-start;

        .txt {
          @media screen and (max-width: $screen-xs) {
            margin-left: 10px;
          }
        }
      }

      .txt-right {
        justify-content: flex-end;

        .txt {
          @media screen and (max-width: $screen-xs) {
            margin-right: 10px;
          }

          @media #{$mobile} {
            flex: 0 0 70%;
          }

          @media #{$tablet} {
            flex: 0 0 45%;
          }

          @media #{$normal} {
            flex: 0 0 43%;
          }
        }
      }

      .txt {
        padding: 55px 30px;

        @media screen and (max-width: $screen-sm) {
          background: rgba(255, 255, 255, 0.85);
        }

        @media #{$mobile} {
          flex: 0 0 65%;
        }

        @media #{$tablet} {
          flex: 0 0 63%;
        }

        @media #{$normal} {
          flex: 0 0 50%;
        }

        .views-field-field-topic {
          color: $brand-primary;
          font-size: $font-size-base * 2;
          font-weight: 700;
          line-height: 1.1;
          margin-bottom: 0.5rem;

          @media screen and (max-width: $screen-xs) {
            font-size: $font-size-base * 1.5;
          }
        }

        .views-field-title {
          font-size: $font-size-base;
          font-weight: 600;
          line-height: 1.1;
          margin-bottom: 2rem;

          a {
            color: $text-color;
          }
        }

        .views-field-body {
          font-size: $font-size-base * 0.9;
          line-height: 1.2;

          @media screen and (max-width: $screen-xs) {
            font-size: $font-size-base * 0.8;
          }

          a {
            font-weight: 600;
          }

          ul {
            padding-left: 2rem;
          }
        }
      }
    }
  }

  &.view.slick-slider {
    border-bottom: 5px solid lighten($gray-base, 75%);
    border-top: 5px solid lighten($gray-base, 75%);

    > .view-content {
      /* For hiding images while load. */
      opacity: 0;
      visibility: hidden;
      transition: opacity 1s ease;
      -webkit-transition: opacity 1s ease;

      &.slick-initialized {
        visibility: visible;
        opacity: 1;
      }
    }

    .view-content {
      .slick-track {
        align-items: stretch;
        display: flex;
      }

      .views-row {
        display: flex;
        height: auto;
        padding: 0;

        &:before {
          content: none !important;
        }

        & > div {
          background-image: url(https://tpgal.local/sites/w_tpgal/files/featured-content/2018/01/playa.jpg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          min-height: 300px;
          padding: 30px 0px;
          position: relative;
          width: 100%;

          &.txt-right {
            @media screen and (max-width: $screen-sm) {
              background-position: 39%;            }

            @media screen and (max-width: $screen-xs) {
              background-position: 35%;
            }
          }

          &.txt-left {
            @media screen and (max-width: $screen-sm) {
              background-position: 55%;            }

            @media screen and (max-width: $screen-xs) {
              background-position: 60%;
            }
          }

          &:before {
            background-color: transparentize(#FFF, 0.8);
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all .3s;
          }

          & > div {
            @extend .container;
            display: flex;
            height: 100%;
            padding: 0;
            position: relative;

            > div {
              align-items: center;
              display: flex;
              flex-direction: row;
              flex: 0 0 100%;
              height: 100%;
            }
          }
        }

        &:focus,
        &:focus-within {
          & > div {
            &:before {
              background-color: transparentize(#FFF, 0.5);
            }
          }
        }
      }

      .slick-dots {
        line-height: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        bottom: 15px;
        width: 100%;

        li {
          display: inline-block;
          line-height: 0;
          margin: 0 2px;

          button {
            background-color: #fff;
            border: 1px solid $brand-primary;
            border-radius: 50%;
            color: transparent;
            font-size: 0;
            height: 9px;
            line-height: 0;
            margin: 0;
            padding: 0;
            transition: all .2s;
            width: 9px;
          }

          &:hover,
          &:focus,
          &.slick-active {
            button {
              background-color: $brand-primary;
            }
          }
        }
      }
    }
  }
}

article.node-featured-content {
  .field-name-field-topic {
    font-size: $font-size-h2;
    font-weight: 700;
    line-height: 1.1;
  }

  .field-name-field-information {
    color: $brand-primary;
    font-size: $font-size-h2;
    font-weight: 600;
    line-height: 1.4;
  }

  .field-name-body {
    font-size: $font-size-large;
    line-height: 1.7;
    margin-top: 15px;
  }
}
