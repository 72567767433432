/**
 * SECTION - Ticket.
 */

.ticket-wrapper {
  border: 1px solid $gray-light;
  margin-bottom: 2em;
  padding: 1em;
  &.booking-ticket-cancelled {
    background: #fff8f8;
  }

  h2 {
    font-size: 1.5em;
    margin: 0.5em 0;
    vertical-align: middle;
  }
  h3 {
    font-size: 1.1em;
    margin: 0 0 0.5em;
  }
  .operator {
    line-height: 1.3em;
  }

  .identifier {
    border-top: 1px dashed $gray-light;
    margin-top: 1em;
    padding-top: 1em;
  }
}
.link-pdf-wrapper {
  text-align: right;

  .link-pdf {
    font-size: 0.9em;
    font-weight: bold;
    text-transform: uppercase;
  }
}