@mixin checkbox {
  & > label {
    min-height: 0;
    padding: 0;

    & > * {
      vertical-align: text-bottom;
    }

    & > .text {
      align-items: center;
      display: inline-flex;

      &:before {
        border: 1px solid transparentize($text-color, 0.7);
        content: '';
        display: inline-block;
        flex-shrink: 0;
        height: 15px;
        margin-bottom: 2px;
        margin-right: 10px;
        transition: all .2s;
        vertical-align: text-bottom;
        width: 15px;
      }
    }

    input {
      opacity: 0;

      &:focus {
        & ~ .text {
          &:before {
            border-color: $brand-primary;
          }
        }
      }

      &:checked {
        & ~ .text {
          &:before {
            background-color: $brand-primary;
            border-color: $brand-primary;
          }
        }
      }
    }

    &:hover,
    &:focus {
      & > .text {
        &:before {
          background-color: transparentize($text-color, 0.7);
        }
      }
    }

    label.error {
      left: auto;
      max-width: none;
      margin: -5px 0 0;
      right: 15px;
      top: 100%;
      min-height: 0;
      cursor: default;
    }
  }
}
