#colorbox {
  .node,
  article {
    padding: 15px;

    header {
      h2 {
        @extend %title-small;
        margin-bottom: 30px;

        a {
          color: $text-color;
          transition: all .2s;

          &:hover,
          &:focus {
            color: $brand-primary;
            text-decoration: none;
          }
        }
      }
    }
  }
}