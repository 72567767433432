/**
 * SECTION - User.
 */
.form-user-wrapper {
  .form-wrapper {
    margin-bottom: 0;
  }
  .form-item.form-group {
    margin-bottom: 15px;
  }
  label.error {
    margin-left: 20px;
  }
  .captcha {
    margin-bottom: 1em;
    margin-top: 1em;
    position: relative;
  }
  .form-item-pass {
    margin: 0 !important;
  }

  .legal-link {
    @include make-md-column(12);
    text-align: right;

    a {
      border-bottom: 2px solid transparentize($text-color, 0.7);
      color: $text-color;
      display: inline-block;
      font-size: .7em;
      font-weight: 600;
      line-height: 1.1;
      transition: all .2s;

      &:hover,
      &:focus {
        border-bottom-color: $brand-primary;
        color: $brand-primary;
        text-decoration: none;
      }
    }
  }
  .form-item-tpgal-legal-checkbox {
    @include make-md-column(12);
    font-size: .7em;
    margin: 0;
    text-align: right;

    @include checkbox;
  }
}
.page-user {
  .form-type-password-confirm {
    .form-type-password {
      @include make-md-column(6);
    }
  }
  .profile {
    .field-items {
      background-color: $input-bg-disabled;
      padding: $padding-base-vertical $padding-base-horizontal;
      border-radius: $input-border-radius;
      margin-bottom: 15px;
    }
  }
  .form-actions {
    margin-top: 22px;
  }
  .btn {
    @media all and (max-width: $screen-xs) {
      margin-left: 0;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  h1 {
    margin-bottom: 1em;
  }
}

.page-user-edit {
  .user-remove-fieldset {
    border: 0;
    -webkit-box-shadow: none;
    legend {
      display: none;
    }
    .panel-body {
      padding: 0;
    }
  }
}
