.rsbtn {
  margin-left: 8px;
  width: auto !important;

  .rsimg {
    background-image: url(../images/icons/rs_button_no_lines.png) !important;
    background-repeat: no-repeat !important;
  }

  & * {
    font-family: $font-family-sans-serif !important;
  }

  .rsbtn_play {
    color: #FFF !important;
    font-weight: 600 !important;

    .rsbtn_left {
      display: none;
    }

    .rsbtn_right {
      background-color: transparent;
      background-position: -1px -219px !important;
      border-radius: 50%;
      height: 20px !important;
      margin: 4px;
      transition: all .2s;
      width: 20px !important;

      &:before {
        background: $readspeaker-color;
        border: 1px solid darken($readspeaker-color, 15%);
        border-radius: 50%;
        content: '';
        display: block;
        height: 40px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all .2s;
        width: 40px;
        z-index: -1;
      }

      &:after {
        animation: pulse-audio 5s ease-out 0s normal infinite backwards;
        background: $readspeaker-color;
        border-radius: 50%;
        content: '';
        display: block;
        height: 20px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        z-index: -2;
        transform-origin: center;
      }

      @keyframes pulse-audio {
        0%, 74% {
          transform: translate(-50%, -50%) scale(0);
          opacity: 0;
        }
        75% {
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.8;
        }
        100% {
          transform: translate(-50%, -50%) scale(3.5);
          opacity: 0;
        }
      }
    }

    &:hover {
      .rsbtn_right {
        background-position: -1px -219px !important;

        &:before {
          background-color: lighten($readspeaker-color, 10%);
          box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .rsbtn_exp {
    //border: 1px solid darken($readspeaker-color, 10%);
    //border-radius: 13px;
    box-sizing: content-box;
    position: relative;
    z-index: 1;

    .rsbtn_pause {
      background-color: transparent;
      background-position-x: -9px !important;
      border-radius: 50%;
      height: 26px !important;
      transition: all .2s;
      width: 26px !important;

      &:before {
        background: $readspeaker-color;
        border: 1px solid darken($readspeaker-color, 10%);
        border-radius: 50%;
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
        content: '';
        display: block;
        height: 40px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all .2s;
        width: 40px;
        z-index: -1;
      }

      &:hover {
        background-position-x: -51px !important;

        &:before {
          background-color: lighten($readspeaker-color, 10%);
        }
      }
    }

    .rsbtn_stop {
      background-color: $readspeaker-color;
      background-position-x: 2px !important;
      margin-left: 7px;
      transition: background-color .2s !important;
      width: 40px !important;
      z-index: -2;

      &:after {
        background-color: inherit;
        bottom: 0;
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 13px;
      }

      &:hover {
        background-color: lighten($readspeaker-color, 10%);
        background-position-x: -40px !important;
      }
    }

    .rsbtn_player {
      background-color: $readspeaker-color;
      background-image: none !important;
      padding-right: 7px;

      .rsbtn_progress_container {
        background-color: lighten($readspeaker-color, 20%);
        background-image: none !important;
        margin-top: 7px !important;
        width: 80px;

        .rsbtn_progress_played {
          background-color: darken($readspeaker-color, 15%);
          background-image: none !important;
        }
      }

      .rsbtn_volume,
      .rsbtn_settings,
      .rsbtn_dl {
        margin: 2px 3px 0 !important;
      }

      .rsbtn_volume_container {
        margin-left: -2px;
        top: calc(100% - 5px);
      }
    }

    .rsbtn_closer {
      background-color: $readspeaker-color;
      background-position: -1px -157px !important;
      border-bottom-right-radius: 13px;
      border-right: 1px solid darken($readspeaker-color, 10%) !important;
      border-top-right-radius: 13px;
      transition: background-color .2s !important;

      &:before {
        background-color: inherit;
        border-left: 1px solid transparentize($text-color, 0.7);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        width: 9px;
      }

      &:hover {
        background-color: lighten($readspeaker-color, 10%);
        background-position: -28px -157px !important;
        border-right: 1px solid darken($readspeaker-color, 10%) !important;
      }
    }

    .rsbtn_stop,
    .rsbtn_player,
    .rsbtn_closer {
      &,
      &:hover {
        border-bottom: 1px solid darken($readspeaker-color, 10%) !important;
        border-top: 1px solid darken($readspeaker-color, 10%) !important;
      }
    }

    .rsbtn_powered {
      display: none;
    }

    .rsbtn_status_overlay {
      border-radius: 13px;
      left: 2px;

      .rsbtn_status {
        margin-top: 3px !important;

        .rsbtn_loaderror {
          margin-left: 5px;
        }
      }
    }

    /**/
    .rsbtn_stop,
    .rsbtn_player,
    .rsbtn_closer {
      transform-origin: left center;
      animation: scale-in linear 0s normal 1 both;
    }

    .rsbtn_stop {
      animation-duration: 0.05s;
    }

    .rsbtn_player {
      animation-duration: 0.2s;
      animation-delay: 0.05s;
    }

    .rsbtn_closer {
      animation-duration: 0.02s;
      animation-delay: 0.25s;
    }

    @keyframes scale-in {
      0% {
        transform: scaleX(0);
      }
      100% {
        transform: scaleX(1);
      }
    }
    /**/
  }

  &.rspaused {
    .rsbtn_exp {
      .rsbtn_pause {
        background-position-x: -8px !important;

        &:hover {
          background-position-x: -50px !important;
        }
      }
    }
  }

  &.rsstopped {
    .rsbtn_exp {
      .rsbtn_stop {
        background-position-x: -261px !important;

        &:hover {
          background-color: $readspeaker-color;
        }
      }
    }
  }

  &.rspopup {
    margin: 0 !important;

    .rsbtn_exp {
      &, .rspart {
        height: 26px !important;
      }

      .rsbtn_pause {
        background-position-y: -78px !important;

        &:hover {
          background-position-y: -78px !important;
        }
      }

      .rsbtn_stop {
        background-position: -241px -78px !important;

        &:hover {
          background-position: -316px -78px !important;
        }
      }

      .rsbtn_player {
        .rsbtn_progress_container {
          margin: 7px 5px 0 3px !important;
        }
      }

      .rsbtn_closer {
        background-position: -220px -162px !important;

        &:hover {
          background-position: -173px -162px !important;
        }
      }
    }

    &.rspaused,
    &.rsstopped {
      .rsbtn_exp {
        .rsbtn_pause {
          background-position: -405px -31px !important;
        }
      }
    }

    &.rsstopped {
      .rsbtn_exp {
        .rsbtn_stop {
          background-position: -304px -161px !important;
        }
      }
    }
  }
}