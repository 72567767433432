.contact-form {
  .form-type-checkbox {
    .control-label {
      display: inline-block;
    }
  }

  .form-control {
    background: transparent;
    color: $text-color;
  }

  .form-textarea {
    resize: vertical;
  }

  & > div {
    @include make-row();
  }

  .form-item {
    @include make-md-column(12);
  }

  .form-item-name,
  .form-item-mail {
    @include make-md-column(6);
  }

  .legal-link {
    @include make-md-column(12);
    margin-top: -20px;
    text-align: right;

    a {
      border-bottom: 2px solid transparentize($text-color, 0.7);
      color: $text-color;
      display: inline-block;
      font-size: .7em;
      font-weight: 600;
      line-height: 1.1;
      transition: all .2s;

      &:hover,
      &:focus {
        border-bottom-color: $brand-primary;
        color: $brand-primary;
        text-decoration: none;
      }
    }
  }

  .form-item-legal-checkbox {
    @include make-md-column(12);
    font-size: .7em;
    margin: 0;
    text-align: right;

    @include checkbox;
  }

  .captcha {
    float: left;
    margin-top: 6px;
    padding: 0 ($grid-gutter-width / 2);
    width: 0;
    position: relative;

    .g-recaptcha {
      transform: scale(0.8);
      transform-origin: left center;
    }

    label.error {
      max-width: none;
      top: calc(100% - 14px);
      white-space: nowrap;
    }
  }

  .form-actions {
    clear: none;
    float: right;
    margin-top: 15px;
    padding: 0 ($grid-gutter-width / 2);
    width: auto;

    .btn {
      min-width: 0;
      padding: 15px 40px 12px;
      @media all and (min-width: $screen-xs) {
        max-width: 150px;
      }
    }
  }

  @media all and (max-width: $screen-xs) {
    .legal-link {
      a {
        font-size: .8em;
      }
    }

    .form-item-legal-checkbox {
      font-size: .8em;

      label {
        .text {
          &:before {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .captcha {
      float: right;
      width: 100%;

      .g-recaptcha {
        transform: scale(1);

        & > div {
          margin-left: auto;
        }
      }
    }

    .form-actions {
      clear: both;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }
}

.logged-in {
  .contact-form {
    .form-actions {
      @include make-md-column(12);

      .btn {
        width: 300px;

        @media all and (max-width: $screen-xs) {
          width: 100%;
        }
      }
    }
  }
}
