ul.pagination {
  float: right;
  margin: 5px 0 0;

  li {
    a,
    span {
      background-color: #FFF;
      border: 1px solid #bababa !important;
      box-shadow: none;
      color: #565656;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 30px;
      min-width: 34px;
      padding: 0 8px;
      transition: all .2s;
      width: auto;

      &:hover,
      &:focus {
        background-color: fade_out($pager-color-accent, 0.3);
        color: $text-color !important;
      }
    }

    &.active {
      a,
      span {
        background-color: $pager-color-accent !important;
        color: $text-color;
        outline: none;
      }
    }
  }
}
