fieldset.search-card {
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  margin: ($header-text-padding / 2) 0 0;
  padding: 40px 15px;

  @media #{$tablet} {
    margin: ($header-text-padding / 2) (-$container-padding-sm) 0;
    padding: 40px 30px;
  }

  @media #{$normal} {
    margin: ($header-text-padding / 2) (-$container-padding-lg) 0;
    padding: 40px 60px;
  }

  .panel-heading {
    background-color: transparent;
    border: 0;
  }

  .panel-body {
    padding: 0;
    @include make-row();

    legend {
      border-width: 0;
      font-size: 16px;
      line-height: 1.785;
      margin-bottom: 1rem;
      text-align: center;
      color: currentColor;
    }

    & > .subtitle {
      margin: 10px auto 30px;
    }

    & > .subtitle > p {
      line-height: 1.7;
      padding: 0 ($grid-gutter-width / 2);
      text-align: center;
    }

    > .form-item {
      font-size: .9em;
      @include make-md-column(6);

      &.form-item-ori,
      &.form-item-des {
        @include make-md-column(3.5);
        @include make-lg-column(4);
      }
      &.form-item-transfer {
        @include make-md-column(3);
        @include make-lg-column(2.3);
      }
      &.form-item-operator,
      &.form-item-type-service,
      &.form-item-discount {
        @include make-md-column(4);
      }
    }

    .container-inline-date {
      clear: none;
      font-size: .9em;
      margin-bottom: 30px;
      @include make-md-column(2);
      @include make-lg-column(1.7);

      * {
        float: none;
      }

      .form-item {
        display: block;
        margin: 0;

        input {
          margin: 0;
          width: 100%;
        }
      }
    }

    .required-txt {
      color: $gray;
      font-size: .85em;
      @include make-md-column(3);
    }

    .form-actions {
      clear: none;
      @include make-md-column(9);

      .btn {
        margin-bottom: 5px;
        margin-left: 15px;

        &.btn-primary {
          float: right;
        }

        @media all and (max-width: $screen-xs) {
          margin-left: 0;
          width: 100%;
        }
      }
    }

    .required-txt,
    .form-actions {
      margin-top: 10px;
    }
  }

  // Expand input group link.
  .input-group {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    .input-group-addon {
      padding: 0;
      a {
        padding: 12px 12px;
      }
    }
  }
}
fieldset.search-card.search-card-other {
  margin-top: 0;
}
.transfer-select + span {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
/** Hide throbber when modal map is loading. */
.input-group .input-group-addon a div {
  display: none;
}

.page-service-search.page-service-search-map-find,
.page-service-search.page-service-search-map-place {
  #header {
    background-position: center 70%;
  }
  h1.page-header {
    display: block;
  }
  .breadcrumb {
    display: none;
  }
}

.page-service-search {
  h1.page-header {
    display: none;
  }
  .download-link-pdf {
    font-weight: bold;
    text-align: right;
    margin: 0;
    clear: both;
    border-top: 1px dashed #bbb;
    padding-top: 0.5em;
    font-size: 0.9em;
  }

  .search-prev-date {
    float: left;
    color: #007bc4;
  }
  .search-next-date {
    float: right;
    color: #007bc4;
  }
  .front-date-control {
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 15px;
    line-height: 28px;
  }
  .alert-info-no-results {
    margin-top: 50px;
  }

}
#center-location-button {
  & > #center-location-image {
    background-position: 0 0;
  }
  &:hover > #center-location-image {
    background-position: -36px 0;
  }
}

#loading-map-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(254, 254, 254, 0.8);
  cursor: progress;
  #loader-map-container {
    position: relative;
    display: block;
    top: 32%;
    margin: 50px;
    #loader-busstops {
      border: 16px solid #007bc4;
      border-radius: 50%;
      border-top: 16px solid #212121;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
      margin: auto;
    }
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

.modal-area-select {
  .form-type-select {
    z-index: 1;
    background: #fff;
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 80%;
    position: absolute;
    display: inline-block;
    margin-top: 10px;
    margin-left: 10px;
    .select2-container {
      width: 100px;
      font-size: 12px;
      padding: 0 15px;
      height: 30px;
      clear: none;
      .select2-selection__arrow {
        height: 28px;
      }
    }
  }
}
.search-box-modal {
  @media all and (max-width: $screen-sm) {
    display: none;
  }
}
.center-loc-container {
  z-index: 1680;
  .select2-search {
    display: none;
  }
}
/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading .loading-modal {
  overflow: hidden;
}
/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .loading-modal {
  display: flex;
}
.loading-modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(254, 254, 254, 0.8) 50% 50% no-repeat;
  cursor: progress;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  #load-modal {
    #modal-loader-container {
      position: sticky;
      display: block;
      #modal-loader {
        border: 16px solid #007bc4;
        border-radius: 50%;
        border-top: 16px solid $gray-darker;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        margin: auto;
      }
    }
    /* Safari */
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

  .load-modal-text {
    text-align: center;
    position: sticky;
    display: block;
    margin-top: 20px;
  }

  h2 {
    position: relative;
    color: $brand-primary;
    font-size: 3em
  }

  h2 .text-animated {
    position: absolute;
    overflow: hidden;
    max-width: 7em;
    white-space: nowrap;
    color: #ddd;
    animation: loading 4s linear infinite;
    height: 54px;
  }
  @keyframes loading {
    0% {
      max-width: 0;
    }
  }
}
