.transfer-info-modal-title {
  padding-bottom: 0;
  border: 0;
}
.transfer-info-modal-body {
  padding-top: 0;
}
.route-info {
  #stops-map {
    #route-modal {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      background: rgba(254, 254, 254, 0.8);
      cursor: progress;

      #route-loader-container {
        position: sticky;
        display: block;
        top: 35%;
        margin: 50px;

        #route-loader {
          border: 16px solid #007bc4;
          border-radius: 50%;
          border-top: 16px solid $gray-darker;
          width: 120px;
          height: 120px;
          -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
          margin: auto;
        }
      }

      /* Safari */
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

    }

    .booking-button {
      float: right;
    }

    .expedition-time-detail {
      @media (min-width: $screen-xs-min) {
        text-align: center;
      }
      font-weight: 800;
      font-size: 1.3em;
    }

    .stops-map {
      $stops-map-line-width: 3px;
      $stops-map-bullet-size: $stops-map-line-width * 4;
      $stops-map-bullet-size-selected: $stops-map-bullet-size * 2;
      list-style: none;
      margin: 20px 0;
      padding: 0;
      @extend .clearfix;

      li {
        clear: both;
        float: right;
        font-size: 1em;
        font-weight: 600;
        line-height: 1;
        padding: 9px 15px;
        position: relative;
        text-align: left;
        width: 50%;

        &:before {
          border-left: $stops-map-line-width solid transparentize($gray-darker, 0.6);
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 0;
        }

        &:after {
          background: $gray-darker;
          border-radius: 50%;
          content: '';
          display: inline-block;
          height: $stops-map-bullet-size;
          left: 0;
          margin-left: -($stops-map-bullet-size / 2) + ($stops-map-line-width / 2);
          margin-top: 2px;
          position: absolute;
          width: $stops-map-bullet-size;
        }

        &.selected {
          color: $gray-darker;
          font-size: 1.1em;
          padding: 9px 20px;
          text-decoration: underline;

          &:after {
            border: 2px solid #fff;
            box-shadow: 0 0 2px 1px;
            height: $stops-map-bullet-size-selected;
            margin-left: -($stops-map-bullet-size-selected / 2) + ($stops-map-line-width / 2);
            margin-top: -2px;
            width: $stops-map-bullet-size-selected;
          }
        }

        &:nth-child(odd) {
          float: left;
          text-align: right;

          &:before,
          &:after {
            left: 100%;
          }
        }

        &:first-child {
          &:before {
            bottom: 0;
            height: 50%;
            top: auto;
          }
        }

        &:last-child {
          &:before {
            height: 50%;
          }
        }

        &.on-demand {
          &:before {
            border-left-color: transparentize($brand-primary, 0.6);
          }

          &:after {
            background: $brand-primary;
          }

          &.selected {
            color: $brand-primary;
          }
        }
      }

      &.stops-map-first-expedition {
        li {
          &:first-child:not(.selected) {
            height: 75px;

            &:before {
              border-left-style: dotted;
              height: 75%;
            }
          }
          &:last-child {
            height: 75px;

            &:before {
              border-left-style: dotted;
              height: 100%;
            }
          }
        }
      }

      &.stops-map-last-expedition {
        li {
          &:last-child:not(.selected) {
            padding-top: 50px;

            &:before {
              border-left-style: dotted;
              height: 75%;
            }
          }
          &:first-child {
            padding-top: 50px;

            &:before {
              border-left-style: dotted;
              height: 100%;
            }
          }
        }
      }

      @media all and (max-width: $screen-xs) {
        padding-left: 30px;

        li {
          width: 100%;
          text-align: left !important;
          float: left !important;

          &:before,
          &:after {
            left: 0 !important;
          }
        }
      }

    }
  }

  .info {
    line-height: 1.3;
    padding: 10px 15px;
  }

  .nav-tabs {
    > li {
      width: 50%;
    }
  }

  .booking-arrow-transfer {
    text-align: center;

    > * {
      font-size: 5em;
      color: #ccc;
    }
  }
}
#gmap-location, #gmap {
  height: 500px;
  width: 100%;
}
.gmap-link {
  background: $brand-primary;
  color: #fff;
  display: block;
  margin: 0 0 0.5em 0;
  padding: 0.5em 1em;

  &:hover, &:focus {
    color: #fff;
  }
}
/*Alert message for route change origin and destination*/
div#route-alert {
  left: 10%;
  width: auto;
  z-index: 9999;
  position: sticky;
  display: block;
  top: 20%;
  margin: 50px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  @media all and (max-width: $screen-xs) {
    margin: 0;
    left: 0;
    width: 100%;
  }

  .text-center {
    padding: 20px 0 15px;

    .alertmsg-refind {
      background: none !important;
      color: #00688d;
      border: 2px solid #00688d;
    }
  }

  #alertmsg_text {
    margin: 15px;
    padding: 15px;
    text-align: justify;
  }

  a {
    color: #fff;
    margin-bottom: 30px;

    &#reFind {
      margin-right: 30px;
      @media all and (max-width: $screen-xs) {
        margin-right: 0;
      }
    }

    span {
      margin-right: 6px;
    }
  }
}
.popover {
  z-index: 1630;
  max-width: 400px;
  border-radius: 1px;

  .popover-content {
    a {
      &.progress-disabled {
        div.ajax-progress {
        }
      }
    }
  }
}
a.use-ajax.set-route-link {
  font-size: 0.8em;
  display: block;
  margin-bottom: 10px;
  padding: 15px 30px 12px;

  &.start {
    &:before {
      content: "";
      width: 30px;
      height: 30px;
      display: inline-block;
      margin-right: 15px;
      vertical-align: inherit;
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(../images/bus_start.svg);
    }
  }

  &.stop {
    &:before {
      content: "";
      width: 30px;
      height: 30px;
      display: inline-block;
      margin-right: 15px;
      vertical-align: inherit;
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(../images/bus_stop.svg);
    }
  }
}
.tooltip {
  z-index: 2000 !important;
}
.departure-info-link,
.expedition-info-link {
  .fa-angle-right {
    transform: rotate(0);
    transition: all 0.1s;
  }
  &[aria-expanded=true] {
    .fa-angle-right {
      transform: rotate(90deg);
      transition: all 0.1s;
    }
  }
}
.departure-info  {
  ul {
    list-style-type: none;
    margin: 0 0 0.6em 0;
    padding: 0 0 0 20px;
    li {
      margin-top: 5px;
    }
  }
}

/*Alert message for route change origin and destination to create booking*/
div#route-booking-alert {
  left: 10%;
  width: auto;
  z-index: 9999;
  position: sticky;
  display: block;
  top: 20%;
  margin: 50px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  @media all and (max-width: $screen-xs) {
    margin: 0;
    left: 0;
    width: 100%;
  }

  .text-center {
    padding: 20px 0 15px;

    .alertmsg-booking {
      background: none;
      color: #00688d;
      border: 2px solid #00688d;
      &:focus {
        background: $gray-darker;
      }
    }
  }

  #alertmsg_text_booking {
    margin: 15px;
    padding: 15px;
    text-align: justify;
  }

  a {
    color: #fff;
    margin-bottom: 30px;
    display: block;
    @media #{$normal} {
      display: inline-block;
    }
    &:first-child {
      margin-right: 0;
      @media #{$normal} {
        margin-right: 30px;
      }
    }

    span {
      margin-right: 6px;
    }
  }
}
.download-link-pdf {
  font-weight: bold;
  text-align: right;
  margin: 0;
  clear: both;
  border-top: 1px dashed #bbb;
  padding-top: 0.5em;
  font-size: 0.9em;
}

div.expedition-subtitle {
  display: inline-block;
  width: 100%;

  .expedition-discount-wrapper {
    .discount-wrapper {
      display: initial;
    }
    .icon {
      vertical-align: sub;
      margin-right: 5px;
      @include youth-card-icon;
    }
  }

  > div {
    width: 50%;
    display: inline-block;
  }
}

div.download-link-pdf.bonuses {
  padding: 0 10px 0 0;
  border-top: none;
  float: right;
}

div.download-link-pdf.transfer-bonuses {
  border-top: none;
  text-align: left;
  margin-bottom: 30px;
  padding: 0;
  line-height: 0;
}

div.transfer-expedition-discount {
  line-height: 1.5em;
  margin-bottom: 20px;

  span.icon {
    vertical-align: sub;
    margin-right: 5px;

    @include youth-card-icon;
  }

  .transfer-discount-description {
    display: inline;
  }
}
