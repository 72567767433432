// Custom
// Forms
$font-size-forms: ceil(($font-size-base * 0.9375)); // ~15px

// Header
$header-text-padding: 50px;

// Container
$container-padding-sm: 30px;
$container-padding-lg: 42px;

// Service links
$service-links-size-sm: 45px;
$service-links-size-lg: 50px;

// Footer
$social-btn-size-sm: 45px;
$social-btn-size-lg: 30px;

// Social networks colors
$facebook-color: #4267b2;
$twitter-color: #1da1f2;
$google-plus-color: #db4437;

// ReadSpeaker
$readspeaker-color: $brand-primary;

// Pagination
$pager-color-accent: hsl(hue($brand-primary) + 2, saturation($brand-primary) + 17, lightness($brand-primary) + 10);

// Colors
// - Survey
$red-frown:              #f7482c;
$red-frown-darker:       #a22412;
$orange-frown:           #f99834;
$orange-frown-darker:    #a25f18;
$yellow-meh:             #fbda3f;
$yellow-meh-darker:      #a58d1d;
$green-meh:              #b8fd3e;
$green-meh-darker:       #75a622;
$green-grin:             #68e54d;
$green-grin-darker:      #3e932c;
