.reload-message {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  bottom: 20px;
  display: none;
  margin: 0 0 0 20px;
  padding-right: 15px;
  position: fixed;
  right: 20px;
  text-align: center;
  z-index: 9999;

  .ajax-progress {
    display: block;
    font-size: 4rem;
    line-height: 3.5rem;
  }
}

.use-ajax {
  svg {
    height: 26px;
    width: 26px;
  }

  .ajax-progress-throbber {
    align-items: center;
    background-color: transparentize(#fff, 0.2);
    bottom: 0;
    display: flex;
    font-size: 1.5em;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 1px;
    z-index: 100;

    i {
      padding: 40px;
      transform-origin: center 49.5%;
    }
  }
}
