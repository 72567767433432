.service-links {
  margin-bottom: 20px;
  position: relative;
  text-align: right;
  top: -12px;

  .links {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 5px;

      a {
        align-items: center;
        border: 1px solid;
        border-radius: 50%;
        box-sizing: content-box;
        color: $text-color;
        display: flex;
        height: $service-links-size-lg;
        justify-content: center;
        text-align: center;
        transition: all .2s;
        width: $service-links-size-lg;

        &:hover {
          color: #FFF !important;
        }

        i, .svg-inline--fa {
          font-size: $service-links-size-lg / 2.5;
          vertical-align: middle;
        }

        @media all and (max-width: $screen-sm) {
          height: $service-links-size-sm;
          width: $service-links-size-sm;

          i, .svg-inline--fa {
            font-size: $service-links-size-sm / 2.5;
          }
        }
      }

      &.service-links-facebook a {
        color: $facebook-color;

        &:hover {
          background-color: $facebook-color;
          border-color: $facebook-color;
        }
      }

      &.service-links-twitter a {
        color: $twitter-color;

        &:hover {
          background-color: $twitter-color;
          border-color: $twitter-color;
        }
      }

      &.service-links-google-plus a {
        color: $google-plus-color;

        &:hover {
          background-color: $google-plus-color;
          border-color: $google-plus-color;
        }
      }
    }
  }
}