// Poll page.
.page-user-booking-survey,
.page-satisfaction-survey {
  .views-exposed-form.clearfix {
    margin-bottom: 30px
  }
  .textarea-poll {
    margin-bottom: 1.5em;
    margin-top: 1em;
  }
  .poll-question {
    margin-bottom: 15px;
  }
  .poll-question-group {
    border-bottom: 1px dotted #ccc;
    margin: 0 0 10px 0;

    .poll-text-principal {
      font-size: 1.1em;
      font-weight: 600
    }
    .poll-text-description {
      font-size: 13px;
    }
    .poll-radios-group {
      display: table;
      table-layout: fixed;
      vertical-align: middle;
      margin-top: 10px;
      @media (max-width: 991.5px) {
        margin-bottom: 0.6em;
        width: 100%;
      }

      > div {
        display: table-cell;
        width: 100%;
        svg {
          width: 30px;
          height: 30px;
          stroke-width: 20px;
          color: white;
          transition: all 300ms;
          &.fa-frown-open {
            color: transparentize($red-frown, 0.91);
            stroke: transparentize($red-frown-darker, 0.7);
            &:hover {
              color: $red-frown;
              stroke: $red-frown-darker;
              transform: scale(1.2);
            }
          }
          &.fa-frown {
            color: transparentize($orange-frown, 0.9);
            stroke: transparentize($orange-frown-darker, 0.64);
            &:hover {
              color: $orange-frown;
              stroke: $orange-frown-darker;
              transform: scale(1.2);
            }
          }
          &.fa-meh {
            color: transparentize($yellow-meh, 0.9);
            stroke: transparentize($yellow-meh-darker, 0.5);
            &:hover {
              color: $yellow-meh;
              stroke: $yellow-meh-darker;
              transform: scale(1.2);
            }
          }
          &.fa-smile {
            color: transparentize($green-meh, 0.9);
            stroke: transparentize($green-meh-darker, 0.5);
            &:hover {
              color: $green-meh;
              stroke: $green-meh-darker;
              transform: scale(1.2);
            }
          }
          &.fa-grin {
            color: transparentize($green-grin, 0.9);
            stroke: transparentize($green-grin-darker, 0.5);
            &:hover {
              color: $green-grin;
              stroke: $green-grin-darker;
              transform: scale(1.2);
            }
          }
        }
        input {
          border: 0;
          clip: rect(1px, 1px, 1px, 1px);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          transition: all 300ms;

          &:checked {
            & + svg.fa-frown-open {
              color: $red-frown;
              stroke: $red-frown-darker;
              transform: scale(1.1);
            }
            & + svg.fa-frown {
              color: $orange-frown;
              stroke: $orange-frown-darker;
              transform: scale(1.1);
            }
            & + svg.fa-meh {
              color: $yellow-meh;
              stroke: $yellow-meh-darker;
              transform: scale(1.1);
            }
            & + svg.fa-smile {
              color: $green-meh;
              stroke: $green-meh-darker;
              transform: scale(1.1);
            }
            & + svg.fa-grin {
              color: $green-grin;
              stroke: $green-grin-darker;
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  // Legal link.
  .public-poll-captcha-wrapper {
    @media #{$tablet} {
      width: auto;
    }
    float: left;
    width: 100%;

    .captcha {
      @media #{$tablet} {
        margin-bottom: 1em;
        position: relative;
        width: 50%;
        display: inline-block;
        float: left;
      }
      width: auto;
      margin-top: 15px;
      float: right;
    }
  }
  .public-poll-btn-wrapper {
    float: right;
  }

  .public-poll-save {
    @media all and (max-width: $screen-xs) {
      width: 100%;
    }
    margin-top: 15px;
    float: right;
  }

  .legal-link {
    @include make-md-column(12);
    text-align: right;

    a {
      border-bottom: 2px solid transparentize($text-color, 0.7);
      color: $text-color;
      display: inline-block;
      font-size: .7em;
      font-weight: 600;
      line-height: 1.1;
      transition: all .2s;

      &:hover,
      &:focus {
        border-bottom-color: $brand-primary;
        color: $brand-primary;
        text-decoration: none;
      }
    }
  }
  .form-item-tpgal-legal-checkbox {
    @include make-md-column(12);
    font-size: .7em;
    margin: 0;
    text-align: right;

    @include checkbox;
  }
}
.page-user-booking-survey {
  .form-actions {
    .btn {
      width: 100%;
      margin-bottom: 1em;
      @media #{$tablet} {
        width: auto;
        margin-bottom: 0;
      }
      &.btn-primary {
        float: right;
        margin-left: 15px;
      }
    }
  }
}
#public_feedback_poll {
  span {
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: fixed;
    text-decoration: none;
    z-index: 1;
    right: 0;
    border: 1px solid #818181;

    &.public_feedback_poll_link {
      @media #{$tablet} {
        top: 50%;
        margin-top: -60px;
        background: #212121;
        color: #fff;
        padding: 0 1em;
        transform: rotate(-90deg);
        transform-origin: 100% 100%;
        svg {
          margin-left: 0.3em;
        }
      }
    }
    &.public_feedback_poll_link_mobile {
      right: auto;
      left: 0;
      z-index: 5;
      background: $red-frown-darker;
      color: white;
      padding: 5px 10px;
      bottom: 0;
      font-size: 1.5em;
      line-height: 1em;
      border-right: 1px solid #fff;
      border-top: 1px solid #fff;
      border-bottom: 0;
      border-left: 0;
      @media #{$tablet} {
        display: none;
      }
    }
  }
}
