/**
 * BLOCKS - Header.
 */

// Goto link.
#skip-link {
  background: #fff;
}

.region-navigation {
  @media all and (min-width: $screen-sm) {
    float: none;
    left: 0;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    position: absolute;
    right: 0;
    top: -30px;
  }

  &:before {
    background: rgba(255, 255, 255, 0.75);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  > .block {
    @media all and (min-width: $screen-sm) {
      float: right;
    }

    ul {
      text-align: center;
      @media all and (min-width: $screen-sm) {
        padding-left: 2em;
      }
    }
  }

  .block-menu {
    li {
      display: inline-block;
      font-size: 0.9em;

      & + li {
        &:before {
          content: "|";
          margin: 0 7px 0 4px;
        }
      }

      a {
        color: $gray-darker;
        display: inline-block;
        font-size: 0.9em;
        padding: 0;
        text-transform: uppercase;
        vertical-align: middle;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// Languages.
.block-locale {

  .language-switcher-locale-url {
    margin-bottom: 0;
    text-align: right;

    li {
      display: inline;
      font-size: 0.9em;

      & + li {
        &:before {
          content: "|";
          margin: 0 7px 0 4px;
        }
      }

      a {
        color: $gray-darker;
        font-size: 0.9em;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        vertical-align: middle;

        &.active {
          font-size: 1em;
          font-weight: 700;
          pointer-events: none;
        }
      }

      .locale-untranslated {
        text-decoration: none;
      }
    }
  }

  @media all and (max-width: $screen-sm - 1) {
    border-bottom: 1px dashed lighten($gray-base, 75%);
    padding: 15px 0 20px;
    position: relative;
    top: 0;

    &:before {
      display: none;
    }

    .language-switcher-locale-url {
      text-align: center;
    }
  }
}

// Default navbar.
.navbar-default.navbar {
  margin: 30px 0 0 0;
  padding: 31px 0 30px 0;

  & > .container {
    align-items: center;
    display: flex;
  }

  .logo {
    padding: 0;

    img {
      height: 36px;

      @media all and (min-width: $screen-sm) {
        height: 48px;
      }
    }
  }

  .logo-xunta-wrapper {
    border-top: 1px dashed lighten($gray-base, 75%);
    padding: 1em 0;
    text-align: center;
  }

  .navbar-wrapper {
    flex: 1;

    .navbar-header {
      .navbar-toggle {
        background-color: transparent !important;
        border: 0;
        height: 21px;
        margin: 0 10px 0 0;
        padding: 0;
        transition: all .2s;
        width: 30px;

        .icon-bar {
          top: calc(50% - 1px);

          &,
          &:before,
          &:after {
            background-color: $gray-dark;
            border-radius: 1px;
            content: '';
            height: 2px;
            left: 0;
            margin: 0;
            position: absolute;
            transition: all .2s;
            width: 100%;
          }

          &:before {
            top: -10px;
          }

          &:after {
            bottom: -10px;
          }

          & + .icon-bar {
            display: none;
          }
        }

        &:active {
          .icon-bar {
            &,
            &:before,
            &:after {
              background-color: $brand-primary;
            }
          }
        }

        backface-visibility: hidden;
      }
    }

    .navbar-collapse {
      nav {
        .navbar-nav {
          float: none;
          text-align: center;

          & > li {
            display: inline-block;
            float: none;
            padding: 10px 20px;

            a {
              padding: 0;
              text-transform: uppercase;
              transition: all .2s;

              &:after {
                border-top: 2px solid $brand-primary;
                bottom: 3px;
                content: '';
                height: 0;
                left: 50px;
                opacity: 0;
                position: absolute;
                transition: all .3s;
                width: 25px;
              }

              &:hover,
              &:focus,
              &.active {
                &:after {
                  left: 0;
                  opacity: 1;
                }
              }

              &.active {
                pointer-events: none;
              }
            }
          }

          &:hover {
            & > li.active:not(:hover) {
              a {
                &:after {
                  opacity: .4 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: $screen-sm - 1) {
    margin: 0;
    padding: 20px 0;
    max-height: 72px;

    .logo {
      flex: 0;
      margin-right: 20px;
      order: 0;
    }

    .navbar-wrapper {
      flex: 1;

      .navbar-collapse {
        position: absolute;
        background-color: #fff;
        left: 0;
        right: 0;
        top: 100%;

        nav {
          .navbar-nav {
            & > li {
              display: block;
              text-align: center;
              font-size: 1.2em;
              padding: 12px 20px;

              a {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.header-texts-wrapper {
  color: #fff;
  padding: $header-text-padding 0;
  text-align: center;
  margin-top: 77px;

  @media all and (min-width: $screen-sm) {
    margin-top: 0;
  }

  & > * {
    margin: 3px 0;
  }

  .title {
    font-size: $font-size-h1;
    font-weight: 900;
    line-height: 1.1;
    text-shadow: 0 0 3px #000;
  }

  .subtitle {
    font-size: $font-size-h2;
    font-weight: 400;
    line-height: 1.1;
    text-shadow: 0 0 2px #000;
  }
}

.not-front:not(.page-service-search) {
  #header {
    background-position: center 70%;

    .header-texts-wrapper {
      h1 {
        font-size: $font-size-h1 / 1.5;
      }

      h2 {
        font-size: $font-size-h2 / 1.5;
      }
    }
  }
}
